import { axiosManager } from '../../axios-manager';
import ContactHistoryCRUDProps from './index.d';

const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzX2luIjoiMjAyNC0xMC0wNyAxOTo0NzoxNC4yNzMzNTQiLCJjcmVhdGVkX2F0IjoiMjAyNC0wOS0wNyAxOTo0NzoxNC4yNzMzODciLCJ1c2VybmFtZSI6Im1hcmNvbmUiLCJ1c2VyX2lkIjoxfQ.3WAr8uTpAGbcvrMvy2xn_0OB3LftDxlzqG83A2L03bg';

class ContactHistoryCRUD {
  create({
    contact_person,
    description,
    prospect_client_id,
    service_type,
    advisor_id,
  }: ContactHistoryCRUDProps.createParams) {
    console.log({
      contact_person,
      description,
      prospect_client_id,
      service_type,
      advisor_id,
    });
    return axiosManager<ContactHistoryCRUDProps.createReturn>({
      method: 'post',
      url: '/contact-history/create',
      auth: token,
      payload: {
        contact_person,
        service_type,
        description,
        prospect_client_id,
        advisor_id
      },
    });
  }
  read({
    page = 1,
    per_page = 10,
    prospect_client_id,
  }: ContactHistoryCRUDProps.readParams) {
    return axiosManager<ContactHistoryCRUDProps.readReturn>({
      method: 'get',
      url: `/contact-history/list?page=${page}&per_page=${per_page}&prospect_client_id=${
        prospect_client_id || 1
      }`,
      auth: token,
      payload: null,
    });
  }
  update({
    contact_person,
    description,
    prospect_client_id,
    service_type,
    advisor_id,
    id,
  }: ContactHistoryCRUDProps.updateParams) {
    return axiosManager<ContactHistoryCRUDProps.updateReturn>({
      method: 'put',
      url: `/contact-history/update/${id}`,
      auth: token,
      payload: {
        contact_person,
        description,
        prospect_client_id,
        service_type,
        advisor_id,
      },
    });
  }
  delete({ id }: ContactHistoryCRUDProps.deleteParams) {
    return axiosManager<ContactHistoryCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/contact-history/delete/${id}`,
      auth: token,
      payload: null,
    });
  }
}

export { ContactHistoryCRUD };
