import React from 'react';
import RoutesComponent from './routes';
import WarningCard from './components/warning-card';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <RoutesComponent />
      <ToastContainer  />
    </div>
  );
}

export default App;
