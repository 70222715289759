import React, { LegacyRef } from 'react';

import typ from './index.d';
import { Modal, Separator } from './styles';
import InputComponent from '../../input';
import { toast } from 'react-toastify';
import { ContactHistoryCRUD } from '../../../services/requests/contact-history';
import InputSelectComponent from '../../input-select';
import { CONST_SERVICES_TYPE } from '../../../utils/CONSTANTS';

const contactHistoryCrud = new ContactHistoryCRUD();

const ContactHistoryModal: React.FC<typ.Props> = ({
  onClose,
  contact,
  prospectClientId,
}) => {
  // States
  const [name, setName] = React.useState(contact?.contact_person || '');
  const [type, setType] = React.useState(contact?.service_type || '');
  const [description, setDescription] = React.useState(
    contact?.description || '',
  );

  const [warning, setWarning] = React.useState({
    name: false,
    type: false,
    description: false,
  });

  const createGraduation = React.useCallback(
    async (name: string, type: string, description: string) => {
      try {
        if (!name && !type && !description) {
          setWarning({
            name: true,
            type: true,
            description: true,
          });
          toast('Digite todos os campos.');
          return;
        }
        if (!name) {
          setWarning(e => ({ ...e, name: true }));
          toast('Digite o nome de contato.');
          return;
        }
        if (!type) {
          setWarning(e => ({ ...e, type: true }));
          toast('Digite o tipo de serviço.');
          return;
        }
        if (!description) {
          setWarning(e => ({ ...e, description: true }));
          toast('Digite a descrição.');
          return;
        }

        const { data, status, error } = await contactHistoryCrud.create({
          contact_person: name,
          description,
          prospect_client_id: Number(prospectClientId),
          service_type: type,
          advisor_id: contact?.advisor_relation?.id || undefined,
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Contato criado com sucesso.');
          onClose();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const editContact = React.useCallback(
    async (name: string, type: string, description: string) => {
      try {
        if (!name && !type && !description) {
          setWarning({
            name: true,
            type: true,
            description: true,
          });
          toast('Digite todos os campos.');
          return;
        }
        if (!name) {
          setWarning(e => ({ ...e, name: true }));
          toast('Digite o nome de contato.');
          return;
        }
        if (!type) {
          setWarning(e => ({ ...e, type: true }));
          toast('Digite o tipo de serviço.');
          return;
        }
        if (!description) {
          setWarning(e => ({ ...e, description: true }));
          toast('Digite a descrição.');
          return;
        }

        let currentServices: string;

        if (
          CONST_SERVICES_TYPE.findIndex(
            item => item.label.toLowerCase() === type.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentServices =
            CONST_SERVICES_TYPE[
              CONST_SERVICES_TYPE.findIndex(
                item => item.label.toLowerCase() === type.toLocaleLowerCase(),
              )
            ].keys;
        } else {
          currentServices = type;
        }

        const { data, status, error } = await contactHistoryCrud.update({
          contact_person: name,
          description,
          prospect_client_id: Number(prospectClientId),
          service_type: currentServices,
          advisor_id: contact?.advisor_relation?.id || undefined,
          id: contact?.id || 0,
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Cliente atualizado com sucesso.');
          onClose();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  return (
    <Modal
      modalTitle={`${contact ? 'Editar' : 'Novo'} cliente`}
      onClose={onClose}
      buttonCallback={() => {
        if (contact) {
          editContact(name, type, description);
          return;
        }
        createGraduation(name, type, description);
      }}
      buttonTitle={`${contact ? 'Editar' : 'Enviar'}`}>
      <InputComponent
        placeholder="Nome do contato"
        type="text"
        value={name}
        setValue={setName}
        warning={warning.name}
      />

      <Separator />

      <InputSelectComponent
        customContainerSize="full"
        value={type}
        setValue={setType}
        placeholder={'Tipo do serviço'}
        warning={warning.type}
        selectData={CONST_SERVICES_TYPE}
      />

      <Separator />

      <InputComponent
        customContainerSize="full"
        value={description}
        setValue={setDescription}
        placeholder={'Descrição'}
        warning={warning.description}
        customStyle={{ height: 110 }}
      />
    </Modal>
  );
};

export default ContactHistoryModal;
