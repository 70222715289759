import { axiosManager } from '../../axios-manager';
import ProspectClientCRUDProps from './index.d';

const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzX2luIjoiMjAyNC0xMC0wNyAxOTo0NzoxNC4yNzMzNTQiLCJjcmVhdGVkX2F0IjoiMjAyNC0wOS0wNyAxOTo0NzoxNC4yNzMzODciLCJ1c2VybmFtZSI6Im1hcmNvbmUiLCJ1c2VyX2lkIjoxfQ.3WAr8uTpAGbcvrMvy2xn_0OB3LftDxlzqG83A2L03bg';

class ProspectClientCRUD {
  create({
    name,
    email,
    phone,
    company_type,
  }: ProspectClientCRUDProps.createParams) {
    return axiosManager<ProspectClientCRUDProps.createReturn>({
      method: 'post',
      url: `/prospect-client/create`,
      auth: token,
      payload: {
        name,
        email,
        phone,
        company_type,
      },
    });
  }

  read({ page = 1, per_page = 10 }: ProspectClientCRUDProps.readParams) {
    let url = `/prospect-client/list?page=${page}&per_page=${per_page}`;
    return axiosManager<ProspectClientCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: token,
      payload: null,
    });
  }

  update({
    company_type,
    email,
    name,
    phone,
    id,
  }: ProspectClientCRUDProps.updateParams) {
    return axiosManager<ProspectClientCRUDProps.updateReturn>({
      method: 'put',
      url: `/prospect-client/update/${id}`,
      auth: token,
      payload: { phone, name, email, company_type },
    });
  }

  delete({ id }: ProspectClientCRUDProps.deleteParams) {
    return axiosManager<ProspectClientCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/prospect-client/delete/${id}`,
      auth: token,
      payload: null,
    });
  }
}

export { ProspectClientCRUD };
