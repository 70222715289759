const CONST_API = {
  baseUrl: 'api.srv555416.hstgr.cloud/',
  protocol: 'https://',
};

const CONST_SEXUALIDADE: enumsBack[] = [
  { keys: 'MAN', label: 'Masculino' },
  { keys: 'WOMAN', label: 'Feminino' },
  { keys: 'UNDEFINED', label: 'Não definido' },
];
const CONST_LEVEL: enumsBack[] = [
  { keys: 'ANALYST', label: 'Analista' },
  { keys: 'ADVISOR', label: 'Assessor' },
  { keys: 'ASSISTANT', label: 'Assistente/Auxiliar' },
  { keys: 'BUYER', label: 'Comprador' },
  { keys: 'CONSULTANT', label: 'Consultor/Auxiliar' },
  { keys: 'COORDINATOR_SUPERVISOR', label: 'Coordenador/Superv/Auxiliar' },
  { keys: 'DIRECTOR', label: 'Diretor/Auxiliar' },
  { keys: 'ASSISTANT', label: 'Assistente/Auxiliar' },
  { keys: 'SPECIALIST', label: 'Especialista' },
  { keys: 'INTERN_TRAINEE', label: 'Estagiário/Trainee' },
  { keys: 'MANAGER', label: 'Gerente' },
  { keys: 'PRESIDENT_VP', label: 'Presidente/VP' },
  { keys: 'TEACHER', label: 'Professor' },
  { keys: 'SECRETARY', label: 'Secretária' },
  { keys: 'SELLER', label: 'Vendedor' },
];

const CONST_OCCUPATION: enumsBack[] = [
  { keys: 'ADMINISTRACAO_GERAL', label: 'Administração Geral' },
  { keys: 'ADMINISTRATIVO', label: 'Administrativo' },
  { keys: 'ADMINISTRATIVO_DE_VENDAS', label: 'Administrativo de Vendas' },
  { keys: 'ADMINISTRATIVO_FINANCEIRO', label: 'Administrativo Financeiro' },
  { keys: 'AGRONEGOCIO', label: 'Agronegócio' },
  { keys: 'AGROPECUARIA_VETERINARIA', label: 'Agropecuária / Veterinária' },
  { keys: 'ARQUITETURA_URBANISMO', label: 'Arquitetura / Urbanismo' },
  { keys: 'ARTES_GRAFICAS', label: 'Artes Gráficas' },
  {
    keys: 'ATENDIMENTO_CLIENTE_RELACIONAMENTO',
    label: 'Atendimento ao Cliente / Relacionamento',
  },
  {
    keys: 'BIOTECNOLOGIA_BIOMEDICAS_BIOQUIMICA',
    label: 'Biotecnologia / Biomédicas / Bioquímica',
  },
  {
    keys: 'CALL_CENTER_TELEMARKETING_SAC',
    label: 'Call Center / Telemarketing / SAC',
  },
  { keys: 'COMERCIAL_VENDAS', label: 'Comercial / Vendas' },
  { keys: 'COMERCIO_EXTERIOR_TRADE', label: 'Comércio Exterior / Trade' },
  { keys: 'COMERCIO_VAREJISTA', label: 'Comércio Varejista' },
  { keys: 'COMPRAS_SUPRIMENTOS', label: 'Compras / Suprimentos' },
  { keys: 'CONSULTORIA_AUDITORIA', label: 'Consultoria e Auditoria' },
  { keys: 'CONTABILIDADE', label: 'Contabilidade' },
  { keys: 'CONTROLADORIA', label: 'Controladoria' },
  { keys: 'DEPT_PESSOAL', label: 'Dept. Pessoal' },
  { keys: 'DESENHO_INDUSTRIAL', label: 'Desenho Industrial' },
  {
    keys: 'DESIGN_DE_INTERIORES_DECORADOR',
    label: 'Design de Interiores / Decorador',
  },
  { keys: 'ECONOMIA', label: 'Economia' },
  { keys: 'EDUCACAO_ENSINO_IDIOMAS', label: 'Educação / Ensino / Idiomas' },
  { keys: 'ENGENHARIA_OUTROS', label: 'Engenharia / Outros' },
  { keys: 'ENGENHARIA_AGRIMENSURA', label: 'Engenharia Agrimensura' },
  { keys: 'ENGENHARIA_AGRONOMICA', label: 'Engenharia Agronômica' },
  { keys: 'ENGENHARIA_CIVIL', label: 'Engenharia Civil' },
  { keys: 'ENGENHARIA_DE_ALIMENTOS', label: 'Engenharia de Alimentos' },
  { keys: 'ENGENHARIA_DE_MATERIAIS', label: 'Engenharia de Materiais' },
  { keys: 'ENGENHARIA_DE_MINAS', label: 'Engenharia de Minas' },
  {
    keys: 'ENGENHARIA_DE_PRODUCAO_INDUSTRIAL',
    label: 'Engenharia de Produção / Industrial',
  },
  {
    keys: 'ENGENHARIA_DE_TELECOMUNICACOES',
    label: 'Engenharia de Telecomunicações',
  },
  { keys: 'ENGENHARIA_DO_MEIO_AMBIENTE', label: 'Engenharia do Meio Ambiente' },
  {
    keys: 'ENGENHARIA_ELETRICA_ELETRONICA',
    label: 'Engenharia Elétrica / Eletrônica',
  },
  {
    keys: 'ENGENHARIA_MECANICA_MECATRONICA',
    label: 'Engenharia Mecânica / Mecatrônica',
  },
  { keys: 'ENGENHARIA_METALURGICA', label: 'Engenharia Metalúrgica' },
  { keys: 'ENGENHARIA_QUIMICA', label: 'Engenharia Química' },
  { keys: 'ENGENHARIA_TEXTIL', label: 'Engenharia Têxtil' },
  { keys: 'ESPORTES_EDUCACAO_FISICA', label: 'Esportes / Educação Física' },
  { keys: 'ESTATISTICA', label: 'Estatística' },
  { keys: 'ESTETICA', label: 'Estética' },
  { keys: 'EVENTOS', label: 'Eventos' },
  { keys: 'FARMACIA', label: 'Farmácia' },
  { keys: 'FINANCEIRA', label: 'Financeira' },
  { keys: 'FISCAL_TRIBUTARIO', label: 'Fiscal/Tributário' },
  { keys: 'FISIOTERAPIA', label: 'Fisioterapia' },
  { keys: 'FONOAUDIOLOGIA', label: 'Fonoaudiologia' },
  { keys: 'GASTRONOMIA', label: 'Gastronomia' },
  { keys: 'GEOLOGIA', label: 'Geologia' },
  { keys: 'HOSPITALAR', label: 'Hospitalar' },
  { keys: 'HOTELARIA_TURISMO', label: 'Hotelaria / Turismo' },
  { keys: 'INDUSTRIAL', label: 'Industrial' },
  {
    keys: 'INTERNET_E_COMMERCE_WEBDESIGN',
    label: 'Internet / E-Commerce / Webdesign',
  },
  { keys: 'JORNALISMO', label: 'Jornalismo' },
  { keys: 'JURIDICA', label: 'Jurídica' },
  { keys: 'LOGISTICA', label: 'Logística' },
  { keys: 'MARKETING', label: 'Marketing' },
  { keys: 'MEDICINA_DO_TRABALHO', label: 'Medicina do Trabalho' },
  { keys: 'MEIO_AMBIENTE_ECOLOGIA', label: 'Meio Ambiente / Ecologia' },
  { keys: 'MODA', label: 'Moda' },
  { keys: 'NEGOCIOS', label: 'Negócios' },
  { keys: 'NUTRICAO', label: 'Nutrição' },
  { keys: 'ODONTOLOGIA', label: 'Odontologia' },
  { keys: 'OUTRAS', label: 'Outras' },
  { keys: 'PUBLICIDADE_E_PROPAGANDA', label: 'Publicidade e Propaganda' },
  { keys: 'QUALIDADE', label: 'Qualidade' },
  { keys: 'QUIMICA', label: 'Química' },
  { keys: 'RECURSOS_HUMANOS', label: 'Recursos Humanos' },
  { keys: 'RELACIONAMENTO_COM_CLIENTES', label: 'Relacionamento com clientes' },
  { keys: 'RELACOES_INTERNACIONAIS', label: 'Relações Internacionais' },
  { keys: 'RELACOES_PUBLICAS', label: 'Relações Públicas' },
  { keys: 'SECRETARIADO', label: 'Secretariado' },
  { keys: 'SEGURANCA_DO_TRABALHO', label: 'Segurança do Trabalho' },
  { keys: 'SEGURANCA_PATRIMONIAL', label: 'Segurança Patrimonial' },
  { keys: 'SERVICO_SOCIAL', label: 'Serviço Social' },
  { keys: 'TECNICO_COMERCIAL', label: 'Técnico / Comercial' },
  { keys: 'TECNICO_OUTROS', label: 'Técnico / Outros' },
  { keys: 'TECNOLOGIA_DA_INFORMACAO', label: 'Tecnologia da Informação' },
  { keys: 'TELECOMUNICACOES', label: 'Telecomunicações' },
  { keys: 'TRADUTOR_INTERPRETE', label: 'Tradutor / Intérprete' },
];

const CONST_VACANCIETYPE: enumsBack[] = [
  { keys: 'PJ', label: 'PJ' },
  { keys: 'CLT', label: 'CLT' },
];
const CONST_PRIORITYS: enumsBack[] = [
  { keys: 'HIGH', label: 'Alta' },
  { keys: 'LOW', label: 'Baixa' },
];

const CONST_LANGUAGES: enumsBack[] = [
  { keys: 'ENGLISH', label: 'Inglês' },
  { keys: 'ESPANISH', label: 'Espanhol' },
  { keys: 'FRENCH', label: 'Francês' },
  { keys: 'GERMAN', label: 'Alemão' },
  { keys: 'JAPANESE', label: 'Japônes' },
  { keys: 'DUTCH', label: 'Holandês' },
  { keys: 'RUSSIAN', label: 'Russo' },
  { keys: 'ITALIAN', label: 'Italiano' },
  { keys: 'CHINESE', label: 'Chinês' },
];

const CONST_LANGUAGES_LEVEL: enumsBack[] = [
  { keys: 'BASIC', label: 'Básico' },
  { keys: 'INTERMEDIATE', label: 'Intermediário' },
  { keys: 'ADVANCED', label: 'Avançado' },
  { keys: 'FLUENT', label: 'Fluente' },
];
const CONST_GRADUATION: enumsBack[] = [
  { keys: 'HIGH_SCHOOL', label: '2o Grau' },
  { keys: 'DOCTORATE', label: 'Doutorado' },
  { keys: 'SPECIALIZATION', label: 'Especialização' },
  { keys: 'UNDERGRADUATE', label: 'Graduação' },
  { keys: 'MBA', label: 'MBA' },
  { keys: 'MASTERS', label: 'Mestrado' },
  { keys: 'POST_GRADUATE', label: 'Pós-Graduação' },
  { keys: 'TECHNICAL', label: 'Técnico' },
  { keys: 'TECHNOLOGIST', label: 'Técnologo' },
];
const CONST_SERVICES_TYPE: enumsBack[] = [
  { keys: 'PROFESSIONAL_OUTPLACEMENT', label: 'Recolocação profissional' },
  { keys: 'RECRUITMENT_SELECTION', label: 'Recrutamento e seleção' },
  { keys: 'TRAINING', label: 'Treinamento' },
  { keys: 'COMPETENCY_ASSESSMENT', label: 'Avaliação por competências' },
  { keys: 'OTHERS', label: 'Outros' },
];
const CONST_STATUS: enumsBack[] = [
  { keys: 'UNAVAILABLE', label: 'Indisponível' },
  { keys: 'AVAILABLE', label: 'Disponível' },
];
const CONST_CLIENT_TYPE: enumsBack[] = [
  { keys: 'CNPJ', label: 'CNPJ' },
  { keys: 'CPF', label: 'CPF' },
];

const CONST_PERSONAL_CLIENT_KAMBAM_STEP: enumsBack[] = [
  { keys: 'REGISTER', label: 'Cadastro' },
  { keys: 'CAREER_RETROSPECTIVE', label: 'Retrospectiva de carreira' },
  { keys: 'APPROVE_RESUME', label: 'Aprovar currículo' },
  { keys: 'SELF_ANALYSIS_FORM', label: 'Formulário de autoanálise' },
  { keys: 'SELF_ANALYSIS_DISCUSSION', label: 'Discussão de autoanálise' },
  { keys: 'MOCK_INTERVIEW', label: 'Entrevista simulada' },
  { keys: 'SIMULATED_DYNAMICS', label: 'Dinâmica simulada' },
  { keys: 'WORKSHOP_LINKEDIN', label: 'Workshop LinkedIn' },
  { keys: 'FINISHED', label: 'Finalizado' },
];
export {
  CONST_API,
  CONST_SEXUALIDADE,
  CONST_OCCUPATION,
  CONST_LEVEL,
  CONST_VACANCIETYPE,
  CONST_PRIORITYS,
  CONST_LANGUAGES,
  CONST_LANGUAGES_LEVEL,
  CONST_GRADUATION,
  CONST_SERVICES_TYPE,
  CONST_STATUS,
  CONST_CLIENT_TYPE,
  CONST_PERSONAL_CLIENT_KAMBAM_STEP,
};

type enumsBack = {
  label: string;
  keys: string;
};
