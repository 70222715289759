import styled from 'styled-components';
import {
  IconBank,
  IconHome,
  IconManagers,
  IconProspect,
  IconRecolocation,
  IconRecrutament,
  IconVancacies,
} from '../../icons';

export const MenuItem = styled.a<{ active?: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.colors.secondaryDark : theme.colors.text};
  font-family: ${({ theme }) => theme.fontFamily.poppins.regular};
  font-size: 20px;
  transition: color 350ms ease;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 25px 0;
  cursor: pointer;
  svg {
    color: ${({ theme, active }) =>
      active ? theme.colors.secondaryDark : theme.colors.text};
    fill: none;
    transition: color 350ms ease;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondaryDark};

    svg {
      color: ${({ theme }) => theme.colors.secondaryDark};
    }
  }
`;

export const Text = styled.p`
  margin: 0px 0 0 15px;
`;

export const HomeIcon = styled(IconHome).attrs(({ theme }) => ({
  width: 34,
  height: 34,
}))``;
export const VancaciesIcon = styled(IconVancacies).attrs(({ theme }) => ({
  width: 34,
  height: 28,
}))``;
export const RecrutamentIcon = styled(IconRecrutament).attrs(({ theme }) => ({
  width: 34,
  height: 36,
}))``;
export const RecolocationIcon = styled(IconRecolocation).attrs(({ theme }) => ({
  width: 34,
  height: 29,
}))``;
export const ManagersIcon = styled(IconManagers).attrs(({ theme }) => ({
  width: 34,
  height: 24,
}))``;
export const BankIcon = styled(IconBank).attrs(({ theme }) => ({
  width: 34,
  height: 27,
}))``;
export const ProspectIcon = styled(IconProspect).attrs(({ theme }) => ({
  width: 34,
  height: 27,
}))``;
