import React, { LegacyRef } from 'react';

import typ from './index.d';
import { Modal, Separator } from './styles';
import InputComponent from '../../input';
import { AdvisorCRUD } from '../../../services/requests/advisor';
import { toast } from 'react-toastify';
import InputSelectComponent from '../../input-select';
import {
  CONST_LANGUAGES,
  CONST_LANGUAGES_LEVEL,
} from '../../../utils/CONSTANTS';
import { LanguageCRUD } from '../../../services/requests/language';

const languageCRUD = new LanguageCRUD();

const LanguageModal: React.FC<typ.Props> = ({
  onClose,
  language,
  vacancyId,
  isVacancy = true,
}) => {
  // Refs
  const inputImageRef = React.useRef<HTMLInputElement>(null);

  // States
  const [name, setName] = React.useState(language?.language_name || '');
  const [level, setLevel] = React.useState(language?.language_level || '');
  const [warning, setWarning] = React.useState({
    name: false,
    level: false,
  });

  const createLanguage = React.useCallback(
    async (name: string, level: string) => {
      try {
        if (!name && !level) {
          setWarning({ level: true, name: true });
          toast('Digite todos os campos.');
          return;
        }
        if (!name) {
          setWarning(e => ({ ...e, name: true }));
          toast('Digite o nome.');
          return;
        }
        if (!level) {
          setWarning(e => ({ ...e, level: true }));
          toast('Digite o email.');
          return;
        }

        const { data, status, error } = await languageCRUD.create({
          id: vacancyId || 0,
          isVancacie: isVacancy,
          language_level: level,
          language_name: name,
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Linguagem associada com sucesso.');
          onClose();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const editLanguage = React.useCallback(
    async (name: string, level: string) => {
      try {
        if (!name && !level) {
          setWarning({ level: true, name: true });
          toast('Digite todos os campos.');
          return;
        }
        if (!name) {
          setWarning(e => ({ ...e, name: true }));
          toast('Digite o nome.');
          return;
        }
        if (!level) {
          setWarning(e => ({ ...e, level: true }));
          toast('Digite o email.');
          return;
        }

        const { data, status, error } = await languageCRUD.update({
          id: vacancyId || 0,
          language_level: level,
          language_name: name,
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Linguagem atualizada com sucesso.');
          onClose();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  return (
    <Modal
      modalTitle={`${language ? 'Editar' : 'Adcionar'} Linguagem`}
      onClose={onClose}
      buttonCallback={() => {
        if (language) {
          editLanguage(name, level);
          return;
        }
        createLanguage(name, level);
      }}
      buttonTitle={`${language ? 'Editar' : 'Enviar'}`}>
      <InputSelectComponent
        customContainerSize="full"
        value={name}
        setValue={setName}
        placeholder={'Nome da Linguagem' || language?.language_name}
        warning={warning.name}
        customStyle={{ background: '#fff' }}
        selectData={CONST_LANGUAGES}
      />
      <Separator />
      <InputSelectComponent
        customContainerSize="full"
        placeholder={'Nivel da Linguagem' || language?.language_level}
        value={level}
        setValue={setLevel}
        customStyle={{ background: '#fff' }}
        warning={warning.level}
        selectData={CONST_LANGUAGES_LEVEL}
      />
    </Modal>
  );
};

export default LanguageModal;
