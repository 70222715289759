import styled from 'styled-components';

export const Container = styled.div`
   height: 100%;
   width: 98px;
   background-color: ${({theme}) => theme.colors.white};
   overflow: hidden;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
   transition: width 350ms ease;
   padding: 32px;
   
   /* position: absolute;
   left: 0px;
   top: 0;
   bottom: 0;
   z-index: 999;
   box-shadow: 0px 8px 24px 0px #00000014; */
   p {
      opacity: 0
   }

   &:hover {
      width: 303px;
      
      p {
         opacity: 1;
      }
   }
`;

export const LogoArea = styled.div`
   display: flex;
   justify-content: flex-start;
   align-items: center;
   margin-bottom: 25px;
`;
export const LogoMenu = styled.img.attrs({src: require('../../assets/images/rx-logo.png'), className: 'img-responsive'})`
   max-width: 42px;
   height: 36px;
`;
export const LogoText = styled.p`
   font-size: 17px;
   color: ${({theme}) => theme.colors.secondary};
   font-family: 'AlumniSans Medium';
   margin: 0px 0 0 15px;
   line-height: 14px;
`;
