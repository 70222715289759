import React from 'react';
import {
  AddConsultantButton,
  AddIcon,
  BackFullIcon,
  Container,
  Content,
  Header,
  HeaderActions,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  RecrutamentContainer,
  RecrutamentCard,
  RecrutamentDeleteButton,
  RecrutamentDescriptionRow,
  RecrutamentDescriptionText,
  TrashIcon,
  EditIcon,
  OptionsIcon,
} from './styles';
import MenuBar from '../../../components/menu-bar';
import ResponsiveContainer from '../../../components/container';
import Search from '../../../components/search';
import RecrutamentManageModal from '../../../components/modais/recrutament/manage';
import RecrutamentViewModal from '../../../components/modais/recrutament/view';
import { useNavigate } from 'react-router-dom';

const RecrutamentPage: React.FC = () => {
  // States
  const [search, setSearch] = React.useState('');

  const [clients, setClients] = React.useState();
  const [client, setClient] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const [showViewModal, setShowViewModal] = React.useState(false);
  const [showManagewModal, setShowManageModal] = React.useState(false);

  // // Callbacks
  // const refreshData = React.useCallback(async (page?: number) => {
  //   try {
  //     setLoading(true);
  //     // const { data, status, error } = await vancacieCRUD.read({
  //     //   page,
  //     //   per_page: 9,
  //     // });

  //     // if (error) {
  //     //   throw new Error(error.message.description);
  //     // }

  //     // if (status === 200 && data) {
  //     //   setClients(data);
  //     // }
  //   } catch (e) {
  //     alert(e);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);

  return (
    <Container>
      <MenuBar currentPage="Recrutamento" />
      <Content>
        <ResponsiveContainer>
          <Header>
            <HeaderBack href="/">
              <BackFullIcon />
            </HeaderBack>
            <HeaderInfo>
              <HeaderTitle>Todos os clientes</HeaderTitle>
              <HeaderActions>
                <Search
                  value={search}
                  setValue={setSearch}
                  placeholder="Pesquisar clientes"
                />
                <AddConsultantButton
                  title="Adcionar cliente"
                  iconRight={AddIcon}
                  callback={() => setShowManageModal(true)}
                />
              </HeaderActions>
            </HeaderInfo>
          </Header>
          <RecrutamentContainer>
            <RecrutamentDescriptionRow>
              <RecrutamentDescriptionText style={{ flex: 1.2 }}>
                Cliente
              </RecrutamentDescriptionText>
              <RecrutamentDescriptionText style={{ flex: 2 }}>
                Vaga
              </RecrutamentDescriptionText>
              <RecrutamentDescriptionText style={{ flex: 2 }}>
                Email
              </RecrutamentDescriptionText>
              <RecrutamentDescriptionText style={{ flex: 2 }}>
                Cnpj
              </RecrutamentDescriptionText>
              <RecrutamentDescriptionText style={{ flex: 0 }}>
                Ação
              </RecrutamentDescriptionText>
            </RecrutamentDescriptionRow>

            <RecrutamentCard>
              <RecrutamentDescriptionText style={{ flex: 1.5 }}>
                Braskem
              </RecrutamentDescriptionText>
              <RecrutamentDescriptionText style={{ flex: 2 }}>
                Técnico em automação
              </RecrutamentDescriptionText>
              <RecrutamentDescriptionText style={{ flex: 2 }}>
                contanto@braskem.com
              </RecrutamentDescriptionText>
              <RecrutamentDescriptionText style={{ flex: 2 }}>
                42.150.391/0001-70
              </RecrutamentDescriptionText>
              <RecrutamentDeleteButton
                style={{ flex: 0 }}
                onClick={() => setShowManageModal(true)}>
                <EditIcon />
              </RecrutamentDeleteButton>
              <RecrutamentDeleteButton style={{ flex: 0 }}>
                <TrashIcon />
              </RecrutamentDeleteButton>
              <RecrutamentDeleteButton
                style={{ flex: 0 }}
                onClick={() => setShowViewModal(true)}>
                <OptionsIcon />
              </RecrutamentDeleteButton>
            </RecrutamentCard>
          </RecrutamentContainer>
        </ResponsiveContainer>
      </Content>
      {showManagewModal && (
        <RecrutamentManageModal
          onClose={() => {
            setShowManageModal(false);
          }}
        />
      )}
      {showViewModal && (
        <RecrutamentViewModal
          onClose={() => {
            setShowViewModal(false);
          }}
        />
      )}
    </Container>
  );
};

export default RecrutamentPage;
