import styled from 'styled-components';
import Button from '../../../components/button';
import {
  IconAdd,
  IconBackFull,
  IconEdit,
  IconList,
  IconMallet,
  IconPhone,
  IconTrash,
} from '../../../components/icons';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  height: 100vh;
  /* background-color: ${({ theme }) => theme.colors.grayUltraHigh} */
  background-color: #f9f9f9;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
`;

export const Header = styled.div`
  padding: 25px 0px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayUltraHigh};
  margin-bottom: 45px;
`;

export const HeaderBack = styled.a`
  margin-bottom: 107px;
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const AddConsultantButton = styled(Button).attrs({
  customStyle: { marginLeft: 40 },
})``;

export const HeaderTitle = styled.h2`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  font-size: 20px;
  margin: 0px;
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  padding-bottom: 50px;
`;

export const Card = styled.a`
  display: flex;
  justify-content: space-between;
  width: calc(100% / 4 - 25px);
  border-radius: 13px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 8px 24px 0px #00000014;
  margin-bottom: 40px;
  margin-right: 25px;
  cursor: pointer;
  transform: scale(1);
  transition: transform 350ms ease;

  &:nth-child(3n) {
    margin-right: 0px;
  }
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const CardSvgRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const CardContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const CardTitle = styled.h2`
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 20px;
`;
export const CardInfos = styled.h4`
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 14px;
`;

export const CardEmail = styled.h4`
  margin: 0px 0 12px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.grayMediumLight};
  font-size: 14px;
`;

export const CardButton = styled.button``;

export const LoadRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const PaginationArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
`;

export const NextPageButton = styled(Button).attrs({
  customStyle: { marginLeft: 10, maxWidth: 30 },
})``;

export const PreviousPageButton = styled(Button).attrs({
  customStyle: { maxWidth: 40 },
})``;

// Icones
export const TrashIcon = styled(IconTrash).attrs({
  width: 20,
  height: 24,
})`
  fill: none;

  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.15);
    }
  }
`;

export const EditIcon = styled(IconEdit).attrs({
  width: 24,
  height: 24,
})`
  fill: none;
  z-index: 999;
  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.15);
    }
  }
`;

export const AddIcon = styled(IconAdd).attrs({
  width: 24,
  height: 24,
})`
  fill: none;
  margin-right: 10px;
`;

export const BackFullIcon = styled(IconBackFull).attrs({
  width: 74,
  height: 22,
})`
  color: #8f8f8f;
  fill: none;
  transition: color 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      color: ${({ theme }) => theme.colors.secondaryDark};
    }
  }
`;

export const PhoneIcon = styled(IconPhone).attrs({
  width: 14,
  height: 14,
})`
  fill: none;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-right: 5px;
`;
export const MalletIcon = styled(IconMallet).attrs({
  width: 14,
  height: 14,
})`
  fill: none;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-right: 5px;
`;
export const ListIcon = styled(IconList).attrs({
  width: 14,
  height: 18,
})`
  fill: none;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-right: 5px;
`;
