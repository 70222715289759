import React from 'react';
import {
  AddConsultantButton,
  AddIcon,
  BackFullIcon,
  Card,
  CardButton,
  CardDescription,
  CardDescriptionTitle,
  CardRow,
  CardsContainer,
  CardTitleArea,
  CardTrashRow,
  Container,
  Content,
  EditIcon,
  EmptyUserIcon,
  Header,
  HeaderActions,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  Input,
  InputRow,
  LoadRow,
  ModalSelect,
  Name,
  NextPageButton,
  PaginationArea,
  PreviousPageButton,
  SearchIcon,
  Select,
  SelectName,
  SelectPhoto,
  TrashIcon,
  Type,
} from './styles';
import MenuBar from '../../../components/menu-bar';
import ResponsiveContainer from '../../../components/container';
import Search from '../../../components/search';
import ProspectModal from '../../../components/modais/prospect';
import Load from '../../../components/load';
import { useTheme } from 'styled-components';
import ProspectClientCRUDProps, {
  ProspectClientData,
} from '../../../services/requests/prospect-client/index.d';
import { toast } from 'react-toastify';
import { ContactHistoryCRUD } from '../../../services/requests/contact-history';
import ContactHistoryCRUDProps, {
  ContactHistoryData,
} from '../../../services/requests/contact-history/index.d';
import { useParams } from 'react-router-dom';
import ContactHistoryModal from '../../../components/modais/contact-history';
import { limitarString } from '../../../utils/convert';
import WarnModal from '../../../components/modais/warn';
import { AdvisorCRUD } from '../../../services/requests/advisor';
import { AdvisorData } from '../../../services/requests/advisor/index.d';

const contactHistoryCrud = new ContactHistoryCRUD();
const advisorCRUD = new AdvisorCRUD();

const ContactHistoryPage: React.FC = () => {
  const { id } = useParams();
  const { colors } = useTheme();
  // Ref
  const currentPage = React.useRef(1);

  // States
  const [advisors, setAdvisors] = React.useState<AdvisorData[]>();
  const [searchAdvisors, setSearchAdvisors] = React.useState('');

  const [search, setSearch] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [showWarn, setShowWarn] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [contacts, setContacts] =
    React.useState<ContactHistoryCRUDProps.readReturn>();
  const [currentContact, setCurrentContact] =
    React.useState<ContactHistoryData>();

  const [currentAdvisorSelect, setCurrentAdvisorSelect] = React.useState<
    number | null
  >(null);

  // Callbacks
  const refreshData = React.useCallback(async (page?: number) => {
    try {
      setLoading(true);
      currentPage.current = page || 1;
      const { data, status, error } = await contactHistoryCrud.read({
        page,
        per_page: 8,
        prospect_client_id: id || '0',
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setContacts(data);
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeleteContact = React.useCallback(async (id: number) => {
    try {
      const { data, status, error } = await contactHistoryCrud.delete({
        id: id,
      });

      if (error) throw new Error(error.message.description);

      if (status === 200) {
        refreshData();
        toast('Contato deletado com sucesso.');
      }
    } catch (e) {
      alert(e);
    }
  }, []);

  const handleCloseModal = React.useCallback(() => {
    setCurrentContact(undefined);
    setShowModal(false);
  }, []);

  const getConsultants = React.useCallback(async () => {
    try {
      setLoading(true);
      const { data, status, error } = await advisorCRUD.read({ page: 1 });

      if (status === 200 && data) {
        setAdvisors(data.Advisor);
      }
      if (error) throw new Error(error.message.description);
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const editContact = React.useCallback(
    async (advisorId: number, idContact: number) => {
      try {
        const { data, status, error } = await contactHistoryCrud.update({
          advisor_id: advisorId || undefined,
          id: idContact || 0,
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Consultor associado com sucesso.');
          setCurrentAdvisorSelect(null)
          refreshData();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const currentData = React.useMemo(() => {
    if (search) {
      let currentFilter = search.toLocaleLowerCase();
      return contacts?.ContactHistory?.filter(e =>
        e.contact_person.toLowerCase().includes(currentFilter),
      );
    }
    return contacts?.ContactHistory;
  }, [contacts, search]);

  const currentDataAdvisors = React.useMemo(() => {
    if (searchAdvisors) {
      let currentFilter = searchAdvisors.toLocaleLowerCase();
      return advisors?.filter(e =>
        e.name.toLowerCase().includes(currentFilter),
      );
    }
    return advisors;
  }, [advisors, searchAdvisors]);

  React.useEffect(() => {
    refreshData();
    getConsultants();
  }, []);

  return (
    <Container>
      <MenuBar currentPage="Prospecção" />
      <Content>
        <ResponsiveContainer>
          <Header>
            <HeaderBack>
              <BackFullIcon />
            </HeaderBack>
            <HeaderInfo>
              <HeaderTitle>Histórico de contato</HeaderTitle>
              <HeaderActions>
                <Search
                  value={search}
                  setValue={setSearch}
                  placeholder="Pesquisar contato"
                />
                <AddConsultantButton
                  title="Adcionar contato"
                  iconRight={AddIcon}
                  callback={() => setShowModal(true)}
                />
              </HeaderActions>
            </HeaderInfo>
          </Header>
          <CardsContainer>
            {loading ? (
              <LoadRow>
                <Load
                  customStyle={{
                    color: colors.secondaryDark,
                  }}
                  size="extraLarge"
                />
              </LoadRow>
            ) : (
              <>
                {currentData && currentData?.length > 0 ? (
                  currentData?.map((e, i) => {
                    return (
                      <Card>
                        <CardRow
                          style={{
                            borderBottomWidth: 1,
                            borderBottomColor: '#ECECEC',
                            borderBottomStyle: 'solid',
                          }}>
                          <CardTitleArea>
                            <Name>{e.contact_person}</Name>
                            <Type>{e.service_type}</Type>
                          </CardTitleArea>
                          <CardButton
                            onClick={() => {
                              if (currentAdvisorSelect === e.id) {
                                setCurrentAdvisorSelect(null);
                                return;
                              }
                              setCurrentAdvisorSelect(e.id);
                            }}>
                            {e.advisor_relation ? (
                              <SelectPhoto
                                src={
                                  e.advisor_relation.image ||
                                  require('../../../assets/temp/temp-user.png')
                                }
                              />
                            ) : (
                              <EmptyUserIcon />
                            )}
                          </CardButton>
                        </CardRow>
                        <CardRow>
                          <CardDescriptionTitle>Descrição</CardDescriptionTitle>
                          <CardButton
                            onClick={() => {
                              setCurrentContact(e), setShowModal(true);
                            }}>
                            <EditIcon />
                          </CardButton>
                        </CardRow>
                        <CardDescription>
                          {limitarString(e.description, 320)}
                        </CardDescription>
                        <CardTrashRow>
                          <CardButton
                            onClick={() => {
                              setCurrentContact(e);
                              setShowWarn(true);
                            }}>
                            <TrashIcon />
                          </CardButton>
                        </CardTrashRow>
                        {currentAdvisorSelect &&
                          currentAdvisorSelect === e.id && (
                            <ModalSelect>
                              <InputRow>
                                <SearchIcon />
                                <Input
                                  value={searchAdvisors}
                                  onChange={e =>
                                    setSearchAdvisors(e.target.value)
                                  }
                                  placeholder="Pesquisar consultor"
                                />
                              </InputRow>
                              {currentDataAdvisors?.map(el => (
                                <Select
                                  onClick={() => {
                                    editContact(el.id, e.id);
                                  }}>
                                  <SelectPhoto
                                    src={
                                      el.image ||
                                      require('../../../assets/temp/temp-user.png')
                                    }
                                  />
                                  <SelectName>{el.name}</SelectName>
                                </Select>
                              ))}
                            </ModalSelect>
                          )}
                      </Card>
                    );
                  })
                ) : (
                  <HeaderTitle>
                    Nenhum histórico de contato encontrado...
                  </HeaderTitle>
                )}
                <PaginationArea>
                  {contacts?.has_prev && (
                    <PreviousPageButton
                      title="Voltar Página"
                      callback={() => refreshData(currentPage.current - 1)}
                    />
                  )}
                  {contacts?.has_next && (
                    <NextPageButton
                      title="Proxima Página"
                      callback={() => refreshData(currentPage.current + 1)}
                    />
                  )}
                </PaginationArea>
              </>
            )}
          </CardsContainer>
        </ResponsiveContainer>
      </Content>
      {showModal && (
        <ContactHistoryModal
          onClose={() => {
            refreshData();
            setCurrentContact(undefined);
            handleCloseModal();
          }}
          contact={currentContact}
          prospectClientId={id || '0'}
        />
      )}
      {showWarn && currentContact && (
        <WarnModal
          title="Remover contato"
          description={`Certeza que quer remover o contato com ${currentContact?.contact_person}?`}
          confirmCallback={() => {
            handleDeleteContact(currentContact?.id || 0);
            setShowWarn(false);
          }}
          declineCallback={() => {
            setCurrentContact(undefined);
            setShowWarn(false);
          }}
        />
      )}
    </Container>
  );
};

export default ContactHistoryPage;
