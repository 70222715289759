import styled from 'styled-components';
import {
  IconDocument,
  IconMatchs,
  IconMoney,
  IconOptions,
  IconPin,
} from '../../icons';
import { StylesProps } from './index.d';
import typ from './index.d';

export const Card = styled.div<{ isAlternative?: boolean }>`
  width: 294px;
  border-radius: 13px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ isAlternative }) =>
    !isAlternative && '0px 8px 24px 0px #00000014'};
  margin-bottom: 20px;
  cursor: pointer;
  /* margin-right: 40px; */
  border: ${({ theme, isAlternative }) =>
    isAlternative && `3px dashed ${theme.colors.black}`};

  &:nth-child(4n) {
    margin-right: 0px;
  }
`;

export const CardRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CardRowForSvg = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #ececec;
  margin-bottom: 8px;
`;

export const Button = styled.button`
  padding: 0px;
`;

export const Name = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 14px;
`;
export const Job = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  color: ${({ theme }) => theme.colors.grayLight};
  font-size: 13px;
`;

export const UserImg = styled.img`
  width: 47px;
  height: 47px;
  border-radius: 25px;
  margin-right: 8px;
`;
export const ConsultantImg = styled.img`
  width: 33px;
  height: 33px;
  border-radius: 25px;
`;

export const Locality = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 14px;
`;

export const Salary = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.secondaryDark};
  font-size: 14px;
`;

export const Matchs = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.blueLight};
  font-size: 14px;
`;

export const MoneyIcon = styled(IconMoney).attrs({
  width: 22,
  height: 17,
})`
  fill: none;
  margin-right: 5px;
`;

export const PinIcon = styled(IconPin).attrs({
  width: 11,
  height: 15,
})`
  fill: none;
  margin-right: 5px;
`;

export const MatchIcon = styled(IconMatchs).attrs({
  width: 29,
  height: 13,
})`
  fill: none;
  margin-right: 5px;
`;
export const OptionsIcon = styled(IconOptions).attrs({
  width: 4,
  height: 28,
})`
  fill: none;
  transform: rotate(90deg);
`;

export const StatusArea = styled.div<StylesProps.userStatus>`
  padding: 3px 5px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ status }) => {
    switch (status) {
      case 'Disponível':
        return '#ABDBCA';
      case 'indisponivel':
        return '#FF565685';
      case 'vinculado':
        return '#A4C9FB';
      case 'independente':
        return '#FADCA2';
    }
  }};
`;

export const StatusComp = styled.div`
  margin-right: 10px;
`;
export const StatusTitle = styled.label<StylesProps.userStatus>`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  font-size: 12px;
  color: ${({ status }) => {
    switch (status) {
      case 'Disponível':
        return '#316F59';
      case 'indisponivel':
        return '#7C0A0A';
      case 'vinculado':
        return '#0A307C';
      case 'independente':
        return '#645942';
    }
  }};
`;
