import React, { LegacyRef } from 'react';

import typ from './index.d';
import {
  DocumentArea,
  DocumentButton,
  DocumentIcon,
  DocumentInput,
  FileName,
  InputsRow,
  Modal,
  PhotoIcon,
} from './styles';
import { TitleSection } from '../../../modal/styles';
import InputComponent from '../../../input';
import EmptyImage from '../../../assets/images/empty-image.png';

const RecrutamentManageModal: React.FC<typ.Props> = ({ onClose }) => {
  // Refs
  const inputCurriculoRef = React.useRef<HTMLInputElement>(null);

  // States
  const [img, setImg] = React.useState('');
  const [name, setName] = React.useState('');
  const [mail, setMail] = React.useState('');
  const [local, setLocal] = React.useState('');
  const [cnpj, setCnpj] = React.useState('');

  const [vacancyName, setVacancyName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [payment, setPayment] = React.useState('');
  const [vacancys, setVacancys] = React.useState('');

  const [file, setFile] = React.useState<File>();

  return (
    <Modal modalTitle="Novo Cliente" onClose={onClose}>
      <TitleSection style={{ marginTop: 0 }}>Dados Pessoais</TitleSection>
      <InputComponent
        placeholder="Nome"
        type="text"
        value={name}
        setValue={setName}
        customStyle={{ marginBottom: 14 }}
      />
      <InputComponent
        placeholder="Email"
        type="email"
        value={mail}
        setValue={setMail}
        customStyle={{ marginBottom: 14 }}
      />
      <InputsRow style={{ marginBottom: 14 }}>
        <InputComponent
          placeholder="Cnpj"
          type="text"
          value={cnpj}
          setValue={setCnpj}
          customStyle={{ marginBottom: 0 }}
          customContainerSize="half"
        />
        <InputComponent
          placeholder="Local"
          type="text"
          value={local}
          setValue={setLocal}
          customStyle={{ marginBottom: 0 }}
          customContainerSize="half"
        />
      </InputsRow>
      <InputsRow>
        <InputComponent
          placeholder="Prazo de contrato"
          type="text"
          value={cnpj}
          setValue={setCnpj}
          customStyle={{ marginBottom: 0 }}
          customContainerSize="full"
        />
        <DocumentButton onClick={() => inputCurriculoRef.current?.click()}>
          <DocumentIcon />
        </DocumentButton>
      </InputsRow>

      {file && <FileName>{file.name}</FileName>}

      <TitleSection>Dados Profissionais</TitleSection>
      <DocumentArea>
        <DocumentInput
          type="file"
          accept="application/pdf"
          ref={inputCurriculoRef}
          onChange={event => {
            if (event && event.target?.files?.[0]) {
              setFile(event.target.files[0]);
            }
          }}
        />
      </DocumentArea>
      <InputComponent
        placeholder="Nome da vaga"
        type="text"
        value={vacancyName}
        setValue={setVacancyName}
        customStyle={{ marginBottom: 14 }}
      />
      <InputComponent
        placeholder="Descrição"
        type="text"
        value={description}
        setValue={setDescription}
        customStyle={{ marginBottom: 14, height: 106 }}
      />
      <InputsRow>
        <InputComponent
          placeholder="Número de vagas"
          type="text"
          value={vacancys}
          setValue={setVacancys}
          customStyle={{ marginBottom: 0 }}
          customContainerSize="half"
        />
        <InputComponent
          placeholder="Remuneração"
          type="text"
          value={payment}
          setValue={setPayment}
          customStyle={{ marginBottom: 0 }}
          customContainerSize="half"
        />
      </InputsRow>
    </Modal>
  );
};

export default RecrutamentManageModal;
