import React from 'react';
import {
  AddConsultantButton,
  AddIcon,
  BackFullIcon,
  ConsultantPhoto,
  ConsultantsCard,
  ConsultantsCardClickArea,
  ConsultantsContainer,
  ConsultantsDeleteButton,
  ConsultantsDescriptionRow,
  ConsultantsDescriptionText,
  Container,
  Content,
  Header,
  HeaderActions,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  LoadRow,
  TrashIcon,
} from './styles';
import MenuBar from '../../../components/menu-bar';
import ResponsiveContainer from '../../../components/container';
import Search from '../../../components/search';
import { AdvisorCRUD } from '../../../services/requests/advisor';
import ConsultantModal from '../../../components/modais/consultant';
import { AdvisorData } from '../../../services/requests/advisor/index.d';
import { transformarData } from '../../../utils/convert';
import Load from '../../../components/load';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';

const advisorCRUD = new AdvisorCRUD();

const ConsultantsPage: React.FC = () => {
  const { colors } = useTheme();

  // States
  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [advisors, setAdvisors] = React.useState<AdvisorData[]>();
  const [search, setSearch] = React.useState('');
  const [currentConsultants, setCurrentConsultant] =
    React.useState<AdvisorData>();

  // Callbacks
  const currentData = React.useMemo(() => {
    if (search) {
      let currentFilter = search.toLocaleLowerCase();
      return advisors?.filter(e =>
        e.name.toLowerCase().includes(currentFilter),
      );
    }
    return advisors;
  }, [advisors, search]);

  const refreshData = React.useCallback(async () => {
    try {
      setLoading(true);
      const { data, status, error } = await advisorCRUD.read({ page: 1 });

      if (status === 200 && data) {
        setAdvisors(data.Advisor);
      }
      if (error) throw new Error(error.message.description);
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteUser = React.useCallback(async (id: number) => {
    try {
      const { status, error } = await advisorCRUD.delete({ id });

      if (error) throw new Error(error.message.description);
      if (status === 200) {
        refreshData();
        toast('Consultor deletado com sucesso.');
      }
    } catch (e) {
      alert(e);
    }
  }, []);

  // Efffects
  React.useEffect(() => {
    refreshData();
  }, []);

  return (
    <Container>
      <MenuBar currentPage="Consultores" />
      <Content>
        <ResponsiveContainer>
          <Header>
            <HeaderBack href="/">
              <BackFullIcon />
            </HeaderBack>
            <HeaderInfo>
              <HeaderTitle>Todos os consultores</HeaderTitle>
              <HeaderActions>
                <Search
                  value={search}
                  setValue={setSearch}
                  placeholder="Pesquisar Consultores"
                />
                <AddConsultantButton
                  title="Adcionar consultor"
                  iconRight={AddIcon}
                  callback={() => setShowModal(true)}
                />
              </HeaderActions>
            </HeaderInfo>
          </Header>
          <ConsultantsContainer>
            {loading ? (
              <LoadRow>
                <Load
                  customStyle={{
                    color: colors.secondaryDark,
                  }}
                  size="extraLarge"
                />
              </LoadRow>
            ) : (
              <>
                <ConsultantsDescriptionRow>
                  <ConsultantsDescriptionText style={{ flex: 3 }}>
                    Nome
                  </ConsultantsDescriptionText>
                  <ConsultantsDescriptionText style={{ flex: 4 }}>
                    Email
                  </ConsultantsDescriptionText>
                  <ConsultantsDescriptionText style={{ flex: 2 }}>
                    Criado em
                  </ConsultantsDescriptionText>
                  <ConsultantsDescriptionText style={{ flex: 0 }}>
                    Ação
                  </ConsultantsDescriptionText>
                </ConsultantsDescriptionRow>

                {currentData && currentData.length > 0 ? (
                  currentData?.map((e, i) => (
                    <ConsultantsCard key={i}>
                      <ConsultantPhoto
                        src={
                          e.image ||
                          require('../../../assets/temp/temp-user.png')
                        }
                      />
                      <ConsultantsCardClickArea
                        onClick={() => {
                          setCurrentConsultant(e);
                          setShowModal(true);
                        }}>
                        <ConsultantsDescriptionText style={{ flex: 2.2 }}>
                          {e.name}
                        </ConsultantsDescriptionText>
                        <ConsultantsDescriptionText style={{ flex: 4 }}>
                          {e.email}
                        </ConsultantsDescriptionText>
                        <ConsultantsDescriptionText style={{ flex: 2 }}>
                          {transformarData(e.created_at)}
                        </ConsultantsDescriptionText>
                      </ConsultantsCardClickArea>
                      <ConsultantsDeleteButton
                        style={{ flex: 0 }}
                        onClick={() => deleteUser(e.id)}>
                        <TrashIcon />
                      </ConsultantsDeleteButton>
                    </ConsultantsCard>
                  ))
                ) : (
                  <HeaderTitle>Nenhuma consultor encontrado...</HeaderTitle>
                )}
              </>
            )}
          </ConsultantsContainer>
        </ResponsiveContainer>
      </Content>
      {showModal && (
        <ConsultantModal
          onClose={() => {
            setCurrentConsultant(undefined);
            setShowModal(false);
            refreshData();
          }}
          consultantData={currentConsultants}
        />
      )}
    </Container>
  );
};

export default ConsultantsPage;
