import React from 'react';
import {
  AddIcon,
  BackFullIcon,
  ButtonAddKeyWord,
  ButtonAddVancacie,
  ButtonPdf,
  ButtonRow,
  Container,
  Content,
  FilesArea,
  Header,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  Image,
  ImageArea,
  ImageInput,
  InputContainer,
  InputsContactRow,
  InputsVancacie,
  InputsVancacieRow,
  KeyWord,
  KeyWordsArea,
  PhotoIcon,
  SectionTitle,
  VacanciesContainer,
} from './styles';
import MenuBar from '../../../../components/menu-bar';
import ResponsiveContainer from '../../../../components/container';
import InputSelectComponent from '../../../../components/input-select';
import {
  CONST_CLIENT_TYPE,
  CONST_OCCUPATION,
  CONST_PERSONAL_CLIENT_KAMBAM_STEP,
  CONST_SEXUALIDADE,
  CONST_STATUS,
} from '../../../../utils/CONSTANTS';
import { toast } from 'react-toastify';
import { PersonalClientCRUD } from '../../../../services/requests/personal-client';
import { convertDateToISO } from '../../../../utils/convert';
import EmptyImage from '../../../../assets/images/empty-image.png';

const personalClientCrud = new PersonalClientCRUD();

const CreatePersonalClient: React.FC = () => {
  // Refs
  const inputImageRef = React.useRef<HTMLInputElement>(null);
  const inputCurriculoRef = React.useRef<HTMLInputElement>(null);

  // States
  const [img, setImg] = React.useState('');
  const [imageBase64, setImageBase64] = React.useState<any>();
  const [curriculo, setCurriculo] = React.useState<File>();

  const [name, setName] = React.useState('');
  const [mail, setMail] = React.useState('');
  const [workplace, setWorkplace] = React.useState('');
  const [budget, setBudget] = React.useState('');
  const [cpf, setCpf] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [birth, setBirth] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [occupation, setOccupation] = React.useState('');
  const [sex, setSex] = React.useState('');
  const [clientType, setClientType] = React.useState('');
  const [kambamStep, setKambamStep] = React.useState('');
  const [childrens, setChildrens] = React.useState('');
  const [civilState, setCivilState] = React.useState('');

  const [keyWord, setKeyWord] = React.useState('');
  const [keyWordList, setKeyWordList] = React.useState<string[]>();

  const [warning, setWarning] = React.useState({
    name: false,
    mail: false,
    workplace: false,
    budget: false,
    cpf: false,
    phone: false,
    birth: false,
    status: false,
    occupation: false,
    sex: false,
    clientType: false,
    kambamStep: false,
    childrens: false,
    civilState: false,
  });

  // Callbacks

  const handleCreateVancancie = React.useCallback(
    async (
      name: string,
      mail: string,
      workplace: string,
      budget: string,
      cpf: string,
      phone: string,
      birth: string,
      status: string,
      occupation: string,
      sex: string,
      clientType: string,
      kambamStep: string,
      childrens: string,
      civilState: string,
      keyWordList: string[],
      image: any,
    ) => {
      if (
        !name &&
        !mail &&
        !cpf &&
        !occupation &&
        !budget &&
        !sex &&
        !phone &&
        !birth &&
        !status &&
        !clientType &&
        !childrens &&
        !civilState &&
        !kambamStep
      ) {
        setWarning({
          name: true,
          mail: true,
          workplace: true,
          budget: true,
          cpf: true,
          phone: true,
          birth: true,
          status: true,
          occupation: true,
          sex: true,
          clientType: true,
          kambamStep: true,
          childrens: true,
          civilState: true,
        });
        toast('Nenhum campo foi preenchido');
        return;
      }
      if (!name) {
        setWarning(e => ({ ...e, name: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }

      try {
        const {
          data,
          status: Status,
          error,
        } = await personalClientCrud.create({
          name,
          advisor_id: 1,
          birth_date: convertDateToISO(birth),
          budget,
          client_type: clientType,
          cpf: cpf.replace(/\D/g, ''),
          email: mail,
          kanban_step: kambamStep,
          key_words: keyWordList,
          local: workplace,
          marital_status: civilState,
          number_children: Number(childrens),
          occupation: occupation,
          personal_phone: phone.replace(/\D/g, ''),
          sexual_gender: sex,
          status,
          image: imageBase64,
        });

        if (error) throw new Error(error.message.description);
        if (Status === 200 && data) {
          toast('Cliente criado com sucesso.');
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const formatPhoneNumber = (value: string) => {
    if (value.length > 15) return;
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    setPhone(value);
  };

  const formatCPF = (value: string) => {
    if (value.length > 14) return; // CPF formatado: 000.000.000-00
    value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Adiciona o primeiro ponto
    value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Adiciona o segundo ponto
    value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Adiciona o hífen antes dos últimos dois dígitos
    setCpf(value);
  };

  const formatDate = (value: string) => {
    if (value.length > 10) return; // Data formatada: DD/MM/AAAA
    value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Adiciona a primeira barra
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Adiciona a segunda barra
    setBirth(value);
  };
  return (
    <Container>
      <MenuBar />
      <Content>
        <ResponsiveContainer>
          <Header>
            <HeaderBack href="/vagas">
              <BackFullIcon />
            </HeaderBack>
            <HeaderInfo>
              <HeaderTitle>Preencha os campos abaixo </HeaderTitle>
            </HeaderInfo>
          </Header>
          <VacanciesContainer>
            <InputContainer>
              <SectionTitle>Dados do Pessoais</SectionTitle>
              <ImageInput
                type="file"
                accept="image/*"
                ref={inputImageRef}
                onChange={event => {
                  if (event && event.target?.files?.[0]) {
                    const file = event.target.files[0];

                    const reader = new FileReader();
                    reader.onloadend = () => {
                      setImageBase64(reader.result);
                    };
                    reader.readAsDataURL(file);
                    setImg(URL.createObjectURL(event.target.files[0]));
                  }
                }}
              />
              <InputsVancacieRow>
                <InputsVancacie
                  customContainerSize="full"
                  customStyle={{ maxWidth: 'none' }}
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={name}
                  setValue={setName}
                  placeholder="Nome*"
                  warning={warning.name}
                />
                <InputsVancacie
                  customContainerSize="half"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={mail}
                  setValue={setMail}
                  placeholder="Email*"
                  warning={warning.mail}
                />
                <InputsVancacie
                  customContainerSize="half"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={workplace}
                  setValue={setWorkplace}
                  placeholder="Local"
                  warning={warning.workplace}
                />
                <InputsVancacie
                  customContainerSize="half"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={cpf}
                  setValue={(e: any) => formatCPF(e)}
                  placeholder="CPF*"
                  warning={warning.cpf}
                />
                <InputsVancacie
                  customContainerSize="half"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={phone}
                  setValue={(e: any) => formatPhoneNumber(e)}
                  placeholder="Telefone*"
                  warning={warning.phone}
                  isPhone
                />
                <InputsVancacie
                  customContainerSize="half"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={birth}
                  setValue={(e: any) => formatDate(e)}
                  placeholder="Data de nascimento*"
                  warning={warning.birth}
                />
                <InputSelectComponent
                  customContainerSize="half"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={sex}
                  setValue={setSex}
                  placeholder="Sexo*"
                  warning={warning.sex}
                  selectData={CONST_SEXUALIDADE}
                />

                <InputsVancacie
                  customContainerSize="half"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={civilState}
                  setValue={setCivilState}
                  placeholder="Estado Civil*"
                  warning={warning.civilState}
                />
                <InputsVancacie
                  customContainerSize="half"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={childrens}
                  setValue={setChildrens}
                  placeholder="Numero de filhos"
                  type="number"
                  warning={warning.childrens}
                />
              </InputsVancacieRow>
            </InputContainer>
            <InputContainer>
              <SectionTitle>Dados de Profissionais</SectionTitle>
              <InputsContactRow>
                <InputSelectComponent
                  customContainerSize="tird"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={status}
                  setValue={setStatus}
                  placeholder="Status*"
                  warning={warning.status}
                  selectData={CONST_STATUS}
                />
                <InputSelectComponent
                  customContainerSize="tird"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={occupation}
                  setValue={setOccupation}
                  placeholder="Cargo*"
                  warning={warning.occupation}
                  selectData={CONST_OCCUPATION}
                />
                <InputSelectComponent
                  customContainerSize="tird"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={kambamStep}
                  setValue={setKambamStep}
                  placeholder="Passo no kambam"
                  selectData={CONST_PERSONAL_CLIENT_KAMBAM_STEP}
                  warning={warning.kambamStep}
                />
                <InputsVancacie
                  customContainerSize="half"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={budget}
                  setValue={setBudget}
                  placeholder="Salário*"
                  warning={warning.budget}
                />
                <InputSelectComponent
                  customContainerSize="half"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={clientType}
                  setValue={setClientType}
                  placeholder="Tipo do cliente"
                  selectData={CONST_CLIENT_TYPE}
                  warning={warning.clientType}
                />
                <InputsVancacie
                  customContainerSize="full"
                  customStyle={{ maxWidth: 'none' }}
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={keyWord}
                  setValue={setKeyWord}
                  placeholder="Palavras chaves">
                  <ButtonAddKeyWord
                    onClick={() => {
                      let current = keyWordList ? keyWordList : [];
                      if (keyWord && keyWord !== '') current.push(keyWord);
                      setKeyWord('');
                      setKeyWordList(current);
                    }}>
                    <AddIcon />
                  </ButtonAddKeyWord>
                </InputsVancacie>
                {keyWordList && keyWordList?.length > 0 && (
                  <KeyWordsArea>
                    {keyWordList.map((e, i) => (
                      <KeyWord>{e}</KeyWord>
                    ))}
                  </KeyWordsArea>
                )}
                <FilesArea>
                  <ImageArea onClick={() => inputImageRef.current?.click()}>
                    <Image src={img ? img : EmptyImage} />
                    <PhotoIcon />
                  </ImageArea>
                  <ButtonPdf>Adicionar currículo</ButtonPdf>
                </FilesArea>
              </InputsContactRow>
            </InputContainer>
          </VacanciesContainer>
          <ButtonRow>
            <ButtonAddVancacie
              title="Adcionar"
              callback={() =>
                handleCreateVancancie(
                  name,
                  mail,
                  workplace,
                  budget,
                  cpf,
                  phone,
                  birth,
                  status,
                  occupation,
                  sex,
                  clientType,
                  kambamStep,
                  childrens,
                  civilState,
                  keyWordList || ['gestao'],
                  imageBase64,
                )
              }
            />
          </ButtonRow>
        </ResponsiveContainer>
      </Content>
    </Container>
  );
};

export default CreatePersonalClient;
