import React from 'react';

import typ from './index.d';
import {
  DocumentArea,
  DocumentButton,
  DocumentIcon,
  DocumentInput,
  Image,
  ImageArea,
  ImageInput,
  Modal,
  PdfTitle,
  PhotoIcon,
} from './styles';
import { TitleSection } from '../../modal/styles';
import InputComponent from '../../input';
import EmptyImage from '../../../assets/images/empty-image.png';
import InputSelectComponent from '../../input-select';
import { CONST_OCCUPATION } from '../../../utils/CONSTANTS';
import { toast } from 'react-toastify';
import { TalentPoolCRUD } from '../../../services/requests/talent-pool';

const talentPoolCRUD = new TalentPoolCRUD();

const CandidateModal: React.FC<typ.Props> = ({ onClose, talentPool }) => {
  // Refs
  const inputImageRef = React.useRef<HTMLInputElement>(null);
  const inputCurriculoRef = React.useRef<HTMLInputElement>(null);

  // States
  const [img, setImg] = React.useState(talentPool?.image || '');
  const [imageBase64, setImageBase64] = React.useState<any>(talentPool?.image);

  const [name, setName] = React.useState(talentPool?.name || '');
  const [mail, setMail] = React.useState(talentPool?.email || '');
  const [local, setLocal] = React.useState(talentPool?.local || '');

  const [occupation, setOccupation] = React.useState(
    talentPool?.occupation || '',
  );
  const [payment, setPayment] = React.useState(talentPool?.budget || '');

  const [curriculo, setCurriculo] = React.useState<File>();

  const [warning, setWarning] = React.useState({
    name: false,
    mail: false,
    local: false,
    payment: false,
    occupation: false,
  });

  const createTalentPool = React.useCallback(
    async (
      name: string,
      mail: string,
      local: string,
      occupation: string,
      payment: string,
      image: any,
      curriculo: any,
    ) => {
      try {
        if (!name && !mail && !local && !occupation && !payment) {
          setWarning({
            mail: true,
            name: true,
            local: true,
            payment: true,
            occupation: true,
          });
          toast('Digite os campos corretamente.');
          return;
        }
        if (!name) {
          setWarning(e => ({ ...e, name: true }));
          toast('Digite o nome.');
          return;
        }
        if (!mail) {
          setWarning(e => ({ ...e, mail: true }));
          toast('Digite o email.');
          return;
        }
        if (!mail.includes('@')) {
          toast('Digite o email corretamente');
          setWarning(e => ({ ...e, mail: true }));
          return;
        }
        if (!local) {
          setWarning(e => ({ ...e, local: true }));
          toast('Digite o local.');
          return;
        }
        if (!occupation) {
          setWarning(e => ({ ...e, occupation: true }));
          toast('Selecione o cargo.');
          return;
        }

        if (!payment) {
          setWarning(e => ({ ...e, payment: true }));
          toast('Digite o salário.');
          return;
        }

        const { data, status, error } = await talentPoolCRUD.create({
          name,
          email: mail,
          budget: payment,
          local,
          occupation,
          image,
          attachment: curriculo,
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Cliente criado com sucesso.');
          onClose();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const editTalentPool = React.useCallback(
    async (
      name: string,
      mail: string,
      local: string,
      occupation: string,
      payment: string,
      image: any,
      curriculo: any,
    ) => {
      try {
        if (!mail.includes('@')) {
          toast('Digite o email corretamente');
          setWarning(e => ({ ...e, mail: true }));
          return;
        }

        let currentOccupation: string;
        
        if (CONST_OCCUPATION.findIndex(item => item.label === occupation) > 0) {
          currentOccupation =
            CONST_OCCUPATION[
              CONST_OCCUPATION.findIndex(item => item.label === occupation)
            ].keys;
        } else {
          currentOccupation = occupation;
        }

        const { status, error } = await talentPoolCRUD.update({
          name,
          email: mail,
          budget: payment,
          local,
          occupation: currentOccupation,
          image,
          attachment: curriculo,
          id: talentPool?.id || 0,
        });
        if (error) throw new Error(error.message.description);
        if (status === 200) {
          onClose();
          toast('Cliente editado com sucesso.');
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  return (
    <Modal
      modalTitle={`${talentPool ? 'Editar' : 'Novo'} cliente`}
      onClose={onClose}
      buttonCallback={() => {
        if (talentPool) {
          editTalentPool(
            name,
            mail,
            local,
            occupation,
            payment,
            imageBase64,
            curriculo,
          );
          return;
        }
        createTalentPool(
          name,
          mail,
          local,
          occupation,
          payment,
          imageBase64,
          curriculo,
        );
      }}>
      <ImageInput
        type="file"
        accept="image/*"
        ref={inputImageRef}
        onChange={event => {
          if (event && event.target?.files?.[0]) {
            const file = event.target.files[0];

            const reader = new FileReader();
            reader.onloadend = () => {
              setImageBase64(reader.result);
            };
            reader.readAsDataURL(file);
            setImg(URL.createObjectURL(event.target.files[0]));
          }
        }}
      />

      <ImageArea onClick={() => inputImageRef.current?.click()}>
        <Image src={img ? img : EmptyImage} />
        <PhotoIcon />
      </ImageArea>

      <TitleSection>Dados Pessoais</TitleSection>
      <InputComponent
        placeholder="Nome"
        type="text"
        value={name}
        setValue={setName}
        customStyle={{ marginBottom: 14 }}
      />
      <InputComponent
        placeholder="Email"
        type="email"
        value={mail}
        setValue={setMail}
        customStyle={{ marginBottom: 14 }}
      />
      <InputComponent
        placeholder="Local"
        type="text"
        value={local}
        setValue={setLocal}
        customStyle={{ marginBottom: 0 }}
      />

      <TitleSection>Dados Profissionais</TitleSection>
      <DocumentArea>
        <DocumentInput
          type="file"
          accept="application/pdf"
          ref={inputCurriculoRef}
          onChange={event => {
            if (event && event.target?.files?.[0]) {
              setCurriculo(event.target.files[0]);
            }
          }}
        />
        <InputSelectComponent
          type="text"
          placeholder="Ocupação"
          value={occupation}
          setValue={setOccupation}
          selectData={CONST_OCCUPATION}
          customStyle={{ marginBottom: 8, width: '95%' }}
        />
        <DocumentButton onClick={() => inputCurriculoRef.current?.click()}>
          <DocumentIcon />
        </DocumentButton>
      </DocumentArea>
      <PdfTitle>{curriculo?.name}</PdfTitle>
      <InputComponent
        placeholder="Remuneração"
        type="number"
        value={payment}
        setValue={setPayment}
      />
    </Modal>
  );
};

export default CandidateModal;
