import React from 'react';

import typ from './index.d';
import {
  Amount,
  AmountIcon,
  Button,
  CalendarIcon,
  CalendarRow,
  Circle,
  Contact,
  ContactSvgRow,
  ContainerIndicator,
  CpfIcon,
  Description,
  DocumentButton,
  DocumentIcon,
  EditIcon,
  InfoArea,
  InfoRow,
  InfoSvgRow,
  Line,
  MailIcon,
  Modal,
  MoneyIcon,
  Name,
  Occupation,
  PinIcon,
  RowTitle,
  Salary,
  TrashIcon,
} from './styles';
import { formatarPhone } from '../../../../utils/convert';

const RecrutamentViewModal: React.FC<typ.Props> = ({ onClose }) => {
  return (
    <Modal modalTitle="Cliente" onClose={onClose} hideButton>
      <InfoRow>
        <DocumentButton>
          <DocumentIcon />
        </DocumentButton>
        <InfoSvgRow style={{ marginBottom: 0 }}>
          <Button>
            <EditIcon />
          </Button>
          <Button>
            <TrashIcon />
          </Button>
        </InfoSvgRow>
      </InfoRow>
      <InfoArea>
        <Name>Braskem</Name>
        <Occupation>Tecnico em automação</Occupation>
        <Description>
          Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
          consectetur, adipisci velit.
        </Description>

        <RowTitle>Dados da empresa</RowTitle>
        <ContactSvgRow>
          <MailIcon />
          <Contact>guilherme@gmail.com</Contact>
        </ContactSvgRow>
        <ContactSvgRow>
          <PinIcon />
          <Contact>Salvador, BA</Contact>
        </ContactSvgRow>
        <ContactSvgRow style={{ marginBottom: 30 }}>
          <CpfIcon></CpfIcon>
          <Contact>10.002.1212/0001-1</Contact>
        </ContactSvgRow>

        <RowTitle>Dados da Vaga</RowTitle>
        <InfoSvgRow>
          <AmountIcon />
          <Amount>5 vagas</Amount>
        </InfoSvgRow>
        <InfoSvgRow style={{ marginBottom: 30 }}>
          <MoneyIcon />
          <Salary>R$ 5000</Salary>
        </InfoSvgRow>

        <RowTitle>Contrato</RowTitle>
        <ContactSvgRow>
          <CalendarIcon />
          <CalendarRow>
            <Contact>10/10/2002</Contact>
            <ContainerIndicator>
              <Circle />
              <Line />
              <Circle />
            </ContainerIndicator>
            <Contact>10/10/2005</Contact>
          </CalendarRow>
        </ContactSvgRow>
      </InfoArea>
    </Modal>
  );
};

export default RecrutamentViewModal;
