import styled from 'styled-components';
import CardModal from '../../modal';
import {
  IconAmount,
  IconArrow,
  IconCpf,
  IconDocument,
  IconEdit,
  IconMail,
  IconMatchs,
  IconMoney,
  IconOccupation,
  IconPhone,
  IconPin,
  IconTrash,
} from '../../icons';
import Button from '../../../components/button';

export const Modal = styled(CardModal)``;

export const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

export const ModalSelectContent = styled.div`
  position: relative;
`;

export const ModalValue = styled.button<{
  background: string;
  textColor?: string;
}>`
  padding: 2px 12px;
  font-size: 16px;
  color: ${({ theme, textColor }) =>
    textColor ? textColor : theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  background-color: ${({ background }) => background};
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

export const ModalSelect = styled.div`
  position: absolute;
  z-index: 999;
  padding: 15px 10px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 16px 40px 0px #0000003d;
  border-radius: 7px;
  width: 204px;
  max-height: 200px;
  overflow-y: scroll;
`;

export const Select = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const SelectName = styled.h4`
  margin: 0px 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 15px;
  transition: color 350ms ease;

  @media only screen and (min-width: 1024px) {
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const ActionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonComp = styled.button``;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;

export const InfoArea = styled.div`
  width: calc(100% / 2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Name = styled.h3`
  margin: 0px 0px 6px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  line-height: 36.57px;
`;

export const Occupation = styled.h3`
  margin: 0px 0px 6px;
  font-size: 20px;
  color: #959595;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
`;

export const Description = styled.h3`
  margin: 0px 0px 10px;
  font-size: 13px;
  color: #525252;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
`;

export const RowTitle = styled.h3`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  margin: 0px;
`;

export const SeeMoreLink = styled.a`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.inter.bold};
  text-decoration: underline;
  transition: color 350ms ease;
  margin: 0 0 15px 10px;

  @media only screen and (min-width: 1024px) {
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const InfoSvgRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const ContactSvgRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const Amount = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 16px;
`;

export const Salary = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.secondaryDark};
  font-size: 16px;
`;

export const Contact = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 16px;
`;

export const KeyWordsArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 18px 0 0;
`;

export const KeyWord = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: #3a3577;
  font-size: 15px;
  background-color: #dfddff;
  border: 1px solid #8075ff;
  border-radius: 4px;
  padding: 3px 9px;
  margin: 0 3px 5px;
`;

export const MatchArea = styled.div`
  width: 100%;
  max-width: 236px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #eef0f4;
  padding: 10px;
  border-radius: 10px;
`;

export const MatchCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  box-shadow: 0px 8px 24px 0px #00000014;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
`;

export const MatchContainer = styled.div`
  width: 100%;
`;

export const CardRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CardRowForSvg = styled.div`
  display: flex;
  align-items: center;
`;

export const UserImg = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 9px;
  margin-right: 8px;
  margin-bottom: 4px;
`;
export const AdvisorImg = styled.img`
  width: 33px;
  height: 33px;
  border-radius: 23px;
`;
export const Matchs = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.blueLight};
  font-size: 14px;
`;

export const SeeMoreButton = styled(Button).attrs({
  customStyle: { minWidth: 0, flex: 0 },
})``;

// User Area

export const UserArea = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const UserInfoArea = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export const UserColumnArea = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const DocumentArea = styled.div`
  width: 41px;
  height: 41px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 0px 30px;
  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.15);
    }
  }
`;

export const DocumentIcon = styled(IconDocument).attrs({
  width: 17,
  height: 20,
})`
  fill: none;
  color: ${({ theme }) => theme.colors.white};
`;

// Icones
export const TrashIcon = styled(IconTrash).attrs({
  width: 20,
  height: 24,
})`
  fill: none;

  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.15);
    }
  }
`;

export const EditIcon = styled(IconEdit).attrs({
  width: 24,
  height: 24,
})`
  fill: none;
  margin-right: 20px;

  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.15);
    }
  }
`;

export const MatchIcon = styled(IconMatchs).attrs({
  width: 29,
  height: 13,
})`
  fill: none;
  margin-right: 5px;
`;

export const AmountIcon = styled(IconAmount).attrs({
  width: 23,
  height: 23,
})`
  fill: none;
  margin-right: 5px;
`;

export const MoneyIcon = styled(IconMoney).attrs({
  width: 22,
  height: 17,
})`
  fill: none;
  margin-right: 5px;
`;

export const PinIcon = styled(IconPin).attrs({
  width: 16,
  height: 21,
})`
  fill: none;
  margin-right: 5px;
`;

export const MailIcon = styled(IconMail).attrs({
  width: 22,
  height: 17,
})`
  fill: none;
  margin-right: 5px;
`;

export const PhoneIcon = styled(IconPhone).attrs({
  width: 17,
  height: 17,
})`
  fill: none;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-right: 5px;
`;

export const CpfIcon = styled(IconCpf).attrs({
  width: 12,
  height: 17,
})`
  fill: none;
  margin-right: 5px;
`;

export const OccupationIcon = styled(IconOccupation).attrs({
  width: 25,
  height: 21,
})`
  fill: none;
  margin-right: 5px;
`;

export const ArrowIcon = styled(IconArrow).attrs({
  width: 11,
  height: 5,
})`
  fill: none;
  margin-left: 5px;
`;
