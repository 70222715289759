import React from 'react';

import typ from './index.d';
import {
  AmountIcon,
  ChosenArea,
  ChosenButton,
  ChosenLine,
  Content,
  MatchIcon,
  Modal,
  MoneyIcon,
  PinIcon,
  SearchArea,
  VacancieAmount,
  VacancieCategory,
  VacancieInfoRow,
  VacancieInfoSvgRow,
  VacancieLocality,
  VacancieMatchs,
  VacancieName,
  VacancieNumber,
  VacancieSalary,
  VacanciesCard,
} from './styles';
import { toast } from 'react-toastify';
import CardUserJob from '../../cards/user-job';
import Search from '../../search';
import { MatchCRUD } from '../../../services/requests/matchs';
import { VancancieCRUD } from '../../../services/requests/vancacies';
import { VancacieData } from '../../../services/requests/vancacies/index.d';
import { PersonalClientCRUD } from '../../../services/requests/personal-client';
import { PersonalClientData } from '../../../services/requests/personal-client/index.d';

const matchCRUD = new MatchCRUD();
const vancacieCRUD = new VancancieCRUD();
const personalClientCRUD = new PersonalClientCRUD();

const MatchModal: React.FC<typ.Props> = ({
  onClose,
  match,
  deleteCallback,
}) => {
  // States
  const [currentMatch, setMatch] = React.useState(match);
  const [client, setClient] = React.useState('');
  const [vacancy, setVacancy] = React.useState('');
  const [warning, setWarning] = React.useState({
    searchClient: false,
    searchVacancy: false,
  });

  const [currentVacancy, setCurrentVacancy] = React.useState<VancacieData>();
  const [vacancyLoading, setVacancyLoading] = React.useState(false);

  const [currentPersonalClient, setCurrentPersonalClient] =
    React.useState<PersonalClientData>();
  const [personalClientLoading, setPersonalClientLoading] =
    React.useState(false);

  const handleUpdate = React.useCallback(async (authorized: boolean) => {
    try {
      const { data, status, error } = await matchCRUD.update({
        advisor_id: Number(currentMatch?.advisor_relation.id),
        authorized,
        personal_client_id: Number(currentMatch?.personal_client_relation.id),
        vacancy_id: Number(currentMatch?.vacancy_id),
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        toast(`O match foi ${authorized ? 'aceito' : 'negado'}!`);
        handleOnClose();
      }
    } catch (e) {
      alert(e);
    }
  }, []);

  const getVacancy = React.useCallback(async (page?: number) => {
    try {
      alert(currentMatch);
      setVacancyLoading(true);
      const { data, status, error } = await vancacieCRUD.read({
        id: currentMatch?.vacancy_id.toString(),
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setCurrentVacancy(data.Vacancy[0]);
      }
    } catch (e) {
      alert(e);
    } finally {
      setTimeout(() => {
        setVacancyLoading(false);
      }, 3000);
    }
  }, []);

  const getMatch = React.useCallback(async (page?: number) => {
    try {
      setPersonalClientLoading(true);
      const { data, status, error } = await personalClientCRUD.modalView({
        id: currentMatch?.personal_client_relation.id || 0,
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setCurrentPersonalClient(data.PersonalClient[0]);
      }
    } catch (e) {
      alert(e);
    } finally {
      setTimeout(() => {
        setPersonalClientLoading(false);
      }, 3000);
    }
  }, []);

  const handleOnClose = React.useCallback(() => {
    setCurrentPersonalClient(undefined);
    setCurrentVacancy(undefined);
    setMatch(undefined);
    onClose();
  }, []);

  React.useEffect(() => {
    if (currentMatch) {
      getVacancy();
      getMatch();
    }
  }, []);

  return (
    <Modal
      modalTitle={`${currentMatch ? 'Criar Match' : 'Match'}`}
      onClose={handleOnClose}
      hideButton
      maxWidth={981}
      buttonCallback={() => {
        null;
      }}>
      {!currentMatch && (
        <SearchArea>
          <Search
            placeholder="Pesquisar Vaga"
            value={vacancy}
            setValue={setVacancy}
            customStyle={{ maxWidth: 294, backgroundColor: '#E4E4E4' }}
          />
          <Search
            placeholder="Pesquisar cliente"
            value={client}
            setValue={setVacancy}
            customStyle={{ maxWidth: 339, backgroundColor: '#E4E4E4' }}
          />
        </SearchArea>
      )}
      <Content>
        <CardUserJob
          isAlternative
          customStyle={{ margin: 0 }}
          client={currentPersonalClient}
        />
        <ChosenArea>
          <ChosenLine />
          {!currentMatch ? (
            <ChosenButton accept>Criar</ChosenButton>
          ) : (
            <>
              <ChosenButton onClick={deleteCallback}>Negar</ChosenButton>
              <ChosenLine />
              <ChosenButton accept onClick={() => handleUpdate(true)}>
                Aceitar
              </ChosenButton>
            </>
          )}
          <ChosenLine />
        </ChosenArea>
        <VacanciesCard onClick={() => null}>
          <VacancieNumber>Código da vaga: 1{/* {e.id} */}</VacancieNumber>
          <VacancieInfoRow>
            <VacancieName>{currentVacancy?.name}</VacancieName>
            <VacancieInfoSvgRow>
              <MatchIcon />
              <VacancieMatchs>matchs</VacancieMatchs>
            </VacancieInfoSvgRow>
          </VacancieInfoRow>
          <VacancieCategory>{currentVacancy?.occupation}</VacancieCategory>
          <VacancieInfoSvgRow style={{ marginBottom: 25 }}>
            <AmountIcon />
            <VacancieAmount>
              {currentVacancy?.quantity}
              vagas
            </VacancieAmount>
          </VacancieInfoSvgRow>
          <VacancieInfoRow>
            <VacancieInfoSvgRow>
              <MoneyIcon />
              <VacancieSalary>
                R$
                {currentVacancy?.budget}
              </VacancieSalary>
            </VacancieInfoSvgRow>
            {currentVacancy?.workplace && (
              <VacancieInfoSvgRow>
                <PinIcon />
                <VacancieLocality>{currentVacancy?.workplace}</VacancieLocality>
              </VacancieInfoSvgRow>
            )}
          </VacancieInfoRow>
        </VacanciesCard>
      </Content>
    </Modal>
  );
};

export default MatchModal;

{
  /* <InputSelectComponent
customContainerSize="full"
value={name}
setValue={setName}
placeholder={'Nome da Linguagem' || language?.language_name}
warning={warning.name}
customStyle={{ background: '#fff' }}
selectData={CONST_LANGUAGES}
/>
<Separator />
<InputSelectComponent
customContainerSize="full"
placeholder={'Nivel da Linguagem' || language?.language_level}
value={level}
setValue={setLevel}
customStyle={{ background: '#fff' }}
warning={warning.level}
selectData={CONST_LANGUAGES_LEVEL}
/> */
}
