import { axiosManager } from '../../axios-manager';
import LanguageCRUDProps from './index.d';

const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzX2luIjoiMjAyNC0xMC0wNyAxOTo0NzoxNC4yNzMzNTQiLCJjcmVhdGVkX2F0IjoiMjAyNC0wOS0wNyAxOTo0NzoxNC4yNzMzODciLCJ1c2VybmFtZSI6Im1hcmNvbmUiLCJ1c2VyX2lkIjoxfQ.3WAr8uTpAGbcvrMvy2xn_0OB3LftDxlzqG83A2L03bg';

class LanguageCRUD {
  create({
    language_name,
    language_level,
    id,
    isVancacie,
  }: LanguageCRUDProps.createParams) {
    let data: LanguageCRUDProps.createData = { language_level, language_name };
    if (isVancacie) data.vacancy_id = id;
    else data.personal_client_id = id;

    console.log(data);
    return axiosManager<LanguageCRUDProps.createReturn>({
      method: 'post',
      url: `/language/create`,
      auth: token,
      payload: data,
    });
  }

  read({ isVancacie, id, page = 1, per_page = 10 }: LanguageCRUDProps.readParams) {
    let url = `/language/list?page=${page}&per_page=${per_page}`;

    if (isVancacie) url = url + `&vacancy_id=${id}`;
    else url = url + `&personal_client_id=${id}`;

    return axiosManager<LanguageCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: token,
      payload: null,
    });
  }

  update({ language_level, language_name, id }: LanguageCRUDProps.updateParams) {
    return axiosManager<LanguageCRUDProps.updateReturn>({
      method: 'put',
      url: `/language/update/${id}`,
      auth: token,
      payload: { language_level, language_name },
    });
  }

  delete({ id }: LanguageCRUDProps.deleteParams) {
    return axiosManager<LanguageCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/language/delete/${id}`,
      auth: token,
      payload: null,
    });
  }
}

export { LanguageCRUD };
