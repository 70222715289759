import { axiosManager } from '../../axios-manager';
import MatchCRUDProps from './index.d';

const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzX2luIjoiMjAyNC0xMC0wNyAxOTo0NzoxNC4yNzMzNTQiLCJjcmVhdGVkX2F0IjoiMjAyNC0wOS0wNyAxOTo0NzoxNC4yNzMzODciLCJ1c2VybmFtZSI6Im1hcmNvbmUiLCJ1c2VyX2lkIjoxfQ.3WAr8uTpAGbcvrMvy2xn_0OB3LftDxlzqG83A2L03bg';

class MatchCRUD {
  create({
    language_name,
    language_level,
    id,
    isVancacie,
  }: MatchCRUDProps.createParams) {
    let data: MatchCRUDProps.createData = { language_level, language_name };
    if (isVancacie) data.vacancy_id = id;
    else data.personal_client_id = id;

    console.log(data);
    return axiosManager<MatchCRUDProps.createReturn>({
      method: 'post',
      url: `/language/create`,
      auth: token,
      payload: data,
    });
  }

  read({ page = 1, per_page = 10, vacancy_id }: MatchCRUDProps.readParams) {
    let url = `/vacancy-match/list?page=${page}&per_page=${per_page}`;
    if (vacancy_id) url = url + `&vacancy_id=${vacancy_id}`;

    return axiosManager<MatchCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: token,
      payload: null,
    });
  }

  update({
    advisor_id,
    authorized,
    personal_client_id,
    vacancy_id,
  }: MatchCRUDProps.updateParams) {
    return axiosManager<MatchCRUDProps.updateReturn>({
      method: 'put',
      url: `/vacancy-match/update/${vacancy_id}`,
      auth: token,
      payload: {
        advisor_id,
        authorized,
        personal_client_id,
        vacancy_id,
      },
    });
  }

  delete({ id }: MatchCRUDProps.deleteParams) {
    return axiosManager<MatchCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/vacancy-match/delete/${id}`,
      auth: token,
      payload: null,
    });
  }
}

export { MatchCRUD };
