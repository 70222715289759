import React from 'react';

import { Container, LogoArea, LogoMenu, LogoText } from './styles';
import MenuItemComp from './menu-item';
import typ from './index.d';

const MenuBar: React.FC<typ.Props> = ({ currentPage }) => {

  // alert(currentPage)
  return (
    <Container>
      <LogoArea>
        <LogoMenu />
        <LogoText>
          consultoria <br /> recursos <br /> humanos
        </LogoText>
      </LogoArea>
      <MenuItemComp
        active={currentPage === 'Home' ? true : false}
        title="Home"
        href="/"
      />
      <MenuItemComp
        active={currentPage === 'Vagas'}
        title="Vagas"
        href="/vagas"
      />
      <MenuItemComp
        active={currentPage === 'Recrutamento'}
        title="Recrutamento"
        href="/recrutamento"
      />
      <MenuItemComp
        active={currentPage === 'Recolocação'}
        title="Recolocação"
        href="/recolocacao-profissional"
      />
      <MenuItemComp
        active={currentPage === 'Consultores'}
        title="Consultores"
        href="/consultores"
      />
      <MenuItemComp
        active={currentPage === 'Talentos'}
        title="Talentos"
        href="/banco-de-talentos"
      />
      <MenuItemComp
        active={currentPage === 'Prospecção'}
        title="Prospecção"
        href="/prospeccao"
      />
    </Container>
  );
};

export default MenuBar;
