import { createSlice } from '@reduxjs/toolkit';
import typ from './index.d';

const initialState: typ.Props = {
  isLogged: false,
  user: null,
};

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    signIn: state => {
      state.isLogged = true;
    },
    setCurrentUser: (state, { payload }: typ.setCurrentUserAction) => {
      state.user = payload;
    },
    signOut: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const statusActions = statusSlice.actions;

export default statusSlice.reducer;
