import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from '../pages/auth/login';
import ChangePass from '../pages/auth/change-pass';
import ConfirmMail from '../pages/auth/confirm-mail';
import Home from '../pages/main/home';
import ConsultantsPages from '../pages/main/consultants';
import VacanciesPage from '../pages/main/vacancies';
import TalentPoolPage from '../pages/main/talent-pool';
import RecrutamentPage from '../pages/main/recrutament';
import RecolocationPage from '../pages/main/recolocation';
import CreateVancacie from '../pages/main/vacancies/create';
import VisualizateVancacie from '../pages/main/vacancies/visualizate';
import EditVancacie from '../pages/main/vacancies/edit';
import MatchsPage from '../pages/main/matchs';
import { useAppSelector } from '../hooks/useRedux';
import ProspectClientsPage from '../pages/main/prospect-client';
import ContactHistoryPage from '../pages/main/contact-history';
import CreatePersonalClient from '../pages/main/recolocation/create';
import EditPersonalClient from '../pages/main/recolocation/edit';
import VisualizatePersonalClient from '../pages/main/recolocation/visualizate';

const RoutesComponent: React.FC = () => {
  const isLogged = useAppSelector(state => state.state.isLogged);

  return (
    <BrowserRouter>
      <Routes>
        <Route Component={Home} path="/" />
        <Route Component={ConsultantsPages} path="/consultores" />

        <Route Component={VacanciesPage} path="/vagas" />
        <Route Component={CreateVancacie} path="/criar-vaga" />
        <Route Component={VisualizateVancacie} path="/visualizar-vaga/:id" />
        <Route Component={EditVancacie} path="/editar-vaga/:id" />

        <Route Component={TalentPoolPage} path="/banco-de-talentos" />

        <Route Component={RecrutamentPage} path="/recrutamento" />

        <Route Component={RecolocationPage} path="/recolocacao-profissional" />
        <Route
          Component={CreatePersonalClient}
          path="/criar-cliente-recolocacao"
        />
        <Route
          Component={EditPersonalClient}
          path="/editar-cliente-recolocacao/:id"
        />
        <Route
          Component={VisualizatePersonalClient}
          path="/visualizar-cliente-recolocacao/:id"
        />

        <Route Component={MatchsPage} path="/matchs/:id" />
        <Route Component={ProspectClientsPage} path="/prospeccao" />
        <Route Component={ContactHistoryPage} path="/historico-contato/:id" />
        {/* {isLogged ? (
          <>
            <Route Component={Home} path="/" />
            <Route Component={ConsultantsPages} path="/consultores" />

            <Route Component={VacanciesPage} path="/vagas" />
            <Route Component={CreateVancacie} path="/criar-vaga" />
            <Route
              Component={VisualizateVancacie}
              path="/visualizar-vaga/:id"
            />
            <Route Component={EditVancacie} path="/editar-vaga/:id" />

            <Route Component={TalentBankPage} path="/banco-de-talentos" />

            <Route Component={RecrutamentPage} path="/recrutamento" />

            <Route
              Component={RecolocationPage}
              path="/recolocacao-profissional"
            />
            <Route Component={MatchsPage} path="/matchs" />
          </>
        ) : (
          <>
            <Route Component={LoginPage} path="/" />
            <Route Component={ChangePass} path="/mudar-senha" />
            <Route Component={ConfirmMail} path="/confirmar-email" />
          </>
        )} */}
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
