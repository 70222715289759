import React from 'react';

import {
  ActionsRow,
  Amount,
  AmountIcon,
  ButtonComp,
  CardColumn,
  CardRow,
  CardRowForSvg,
  Contact,
  ContactSvgRow,
  Content,
  CpfIcon,
  Description,
  EditIcon,
  HeaderRow,
  InfoArea,
  InfoSvgRow,
  ModalValue,
  KeyWord,
  KeyWordsArea,
  MailIcon,
  MatchArea,
  MatchCard,
  MatchContainer,
  Modal,
  ModalSelect,
  ModalSelectContent,
  MoneyIcon,
  Name,
  Occupation,
  PhoneIcon,
  PinIcon,
  RowTitle,
  Salary,
  SeeMoreButton,
  SeeMoreLink,
  Select,
  SelectName,
  TrashIcon,
  UserImg,
  AdvisorImg,
  UserArea,
  UserInfoArea,
  UserColumnArea,
  MatchIcon,
  Matchs,
  DocumentArea,
  DocumentIcon,
  OccupationIcon,
  ArrowIcon,
} from './styles';

import typ from './index.d';
import { toast } from 'react-toastify';
import { formatCPF } from '../../../utils/convert';
import { useNavigate } from 'react-router-dom';
import { PersonalClientCRUD } from '../../../services/requests/personal-client';
import { PersonalClientDataModalView } from '../../../services/requests/personal-client/index.d';
import {
  CONST_PERSONAL_CLIENT_KAMBAM_STEP,
  CONST_STATUS,
} from '../../../utils/CONSTANTS';

const personalClientCrud = new PersonalClientCRUD();

const PersonalClientModal: React.FC<typ.Props> = ({
  onClose,
  personalClient,
  refreshData,
}) => {
  const navigate = useNavigate();

  const [client, setClient] = React.useState<PersonalClientDataModalView>();

  const [kambamStep, setKambamStep] = React.useState<{
    id: number;
    color: string;
    title: string;
  }>(KAMBAM_STEPS[0]);
  const [showKambamModal, setShowKambamModal] = React.useState(false);

  const [status, setStatus] = React.useState<{
    id: number;
    color: string;
    title: string;
    textColor?: string;
  }>(STATUS[0]);
  const [showStatusModal, setShowStatusModal] = React.useState(false);

  // Callbacks
  const handleUpdateVancancie = React.useCallback(
    async (status?: string, kambamStep?: string) => {
      let currentStatus: string = '';
      let currentKambam: string = '';

      if (kambamStep) {
        if (
          CONST_PERSONAL_CLIENT_KAMBAM_STEP.findIndex(
            item => item.label.toLowerCase() === kambamStep.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentKambam =
            CONST_PERSONAL_CLIENT_KAMBAM_STEP[
              CONST_PERSONAL_CLIENT_KAMBAM_STEP.findIndex(
                item =>
                  item.label.toLowerCase() === kambamStep.toLocaleLowerCase(),
              )
            ].keys;
        } else {
          currentKambam = kambamStep;
        }
      }

      if (status) {
        if (
          CONST_STATUS.findIndex(
            item => item.label.toLowerCase() === status.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentStatus =
            CONST_STATUS[
              CONST_STATUS.findIndex(
                item => item.label.toLowerCase() === status.toLocaleLowerCase(),
              )
            ].keys;
        } else {
          currentStatus = status;
        }
      }

      const dataApi: any = {};
      if (currentKambam) dataApi.kanban_step = currentKambam;
      if (currentStatus) dataApi.status = currentStatus;

      dataApi.id = personalClient;

      try {
        const {
          data,
          status: Status,
          error,
        } = await personalClientCrud.update(dataApi);

        if (error) throw new Error(error.message.description);
        if (Status === 200 && data) {
          toast('Cliente editado com sucesso.');
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const deletePersonalClient = React.useCallback(async (id: number) => {
    try {
      const { data, status, error } = await personalClientCrud.delete({ id });

      if (error) throw new Error('Erro ao deletar usuario.');

      if (status === 200) {
        toast('Cliente deletado com sucesso.');
        refreshData();
        onClose();
      }
    } catch (e) {
      toast(e as String);
    }
  }, []);

  const getClient = React.useCallback(async (page?: number) => {
    try {
      const { data, status, error } = await personalClientCrud.modalView({
        id: personalClient || 0,
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setClient(data.PersonalClient[0]);

        let currentKambam: any = {};
        let currentStatus: any = {};

        if (
          KAMBAM_STEPS.findIndex(
            item =>
              item.title.toLowerCase() ===
              data.PersonalClient[0].kanban_step.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentKambam =
            KAMBAM_STEPS[
              KAMBAM_STEPS.findIndex(
                item =>
                  item.title.toLowerCase() ===
                  data.PersonalClient[0].kanban_step.toLocaleLowerCase(),
              )
            ];
        }
        if (
          STATUS.findIndex(
            item =>
              item.title.toLowerCase() ===
              data.PersonalClient[0].status.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentStatus =
            STATUS[
              STATUS.findIndex(
                item =>
                  item.title.toLowerCase() ===
                  data.PersonalClient[0].status.toLocaleLowerCase(),
              )
            ];
        }

        setKambamStep(currentKambam);
        setStatus(currentStatus);
      }
    } catch (e) {
      alert(e);
    } finally {
      setTimeout(() => {}, 3000);
    }
  }, []);

  React.useEffect(() => {
    getClient();
  }, []);

  return (
    <Modal modalTitle="Cliente" onClose={onClose} hideButton maxWidth={687}>
      <HeaderRow>
        <ModalSelectContent>
          <ModalValue
            background={kambamStep?.color}
            onClick={() => {
              setShowKambamModal(true);
            }}>
            {kambamStep?.title} {'>'}{' '}
          </ModalValue>
          {showKambamModal && (
            <ModalSelect>
              {KAMBAM_STEPS?.map(el => (
                <Select
                  onClick={() => {
                    // editContact(el.id, e.id);
                    setKambamStep(el);
                    handleUpdateVancancie(undefined, el.title);
                    setShowKambamModal(false);
                  }}>
                  <SelectName key={el.id}>{el.title}</SelectName>
                </Select>
              ))}
            </ModalSelect>
          )}
        </ModalSelectContent>
        <ActionsRow>
          <ButtonComp
            onClick={() =>
              navigate(`/editar-cliente-recolocacao/${personalClient}`)
            }>
            <EditIcon />
          </ButtonComp>
          <ButtonComp
            onClick={() => {
              deletePersonalClient(personalClient || 0);
            }}>
            <TrashIcon />
          </ButtonComp>
        </ActionsRow>
      </HeaderRow>

      <HeaderRow>
        <ModalSelectContent>
          <ModalValue
            background={status?.color}
            onClick={() => {
              setShowStatusModal(true);
            }}
            textColor={status.textColor}>
            {status?.title}
            <ArrowIcon style={{ color: status.textColor }} />
          </ModalValue>
          {showStatusModal && (
            <ModalSelect>
              {STATUS?.map(el => (
                <Select
                  onClick={() => {
                    // editContact(el.id, e.id);
                    setStatus(el);
                    handleUpdateVancancie(el.title, undefined);
                    setShowStatusModal(false);
                  }}>
                  <SelectName key={el.id}>{el.title}</SelectName>
                </Select>
              ))}
            </ModalSelect>
          )}
        </ModalSelectContent>
        <AdvisorImg
          src={
            // e.personal_client_relation.image ||
            require('../../../assets/temp/temp-user.png')
          }
        />
      </HeaderRow>

      <Content>
        <InfoArea>
          <UserArea>
            <UserImg src={require('../../../assets/temp/temp-user.png')} />
            <UserInfoArea>
              <Name>{client?.name}</Name>
              <CardRow>
                <UserColumnArea>
                  <InfoSvgRow style={{ marginBottom: 4 }}>
                    <MatchIcon />
                    <Matchs>{client?.vacancy_matching_quantity} matchs</Matchs>
                  </InfoSvgRow>
                  {client?.budget && (
                    <InfoSvgRow style={{ marginBottom: 0 }}>
                      <MoneyIcon />
                      <Salary>R$ {client?.budget}</Salary>
                    </InfoSvgRow>
                  )}
                </UserColumnArea>
                <DocumentArea>
                  <DocumentIcon />
                </DocumentArea>
              </CardRow>
            </UserInfoArea>
          </UserArea>
          <InfoSvgRow>
            <RowTitle>Dados pessoais </RowTitle>
            {/* <SeeMoreLink href={`/visualizar-vaga/${vancacie?.id}`}>
              Ver mais
            </SeeMoreLink> */}
          </InfoSvgRow>
          <ContactSvgRow>
            <MailIcon />
            <Contact>{client?.email}</Contact>
          </ContactSvgRow>
          {client?.local && (
            <ContactSvgRow>
              <PinIcon />
              <Contact>{client.local}</Contact>
            </ContactSvgRow>
          )}
          {client?.cpf && (
            <ContactSvgRow>
              <CpfIcon />
              <Contact>{formatCPF(client.cpf)}</Contact>
            </ContactSvgRow>
          )}

          <InfoSvgRow>
            <RowTitle>Dados profissonais</RowTitle>
            {/* <SeeMoreLink href={`/visualizar-vaga/${vancacie?.id}`}>
              Ver mais
            </SeeMoreLink> */}
          </InfoSvgRow>
          {client?.occupation && (
            <ContactSvgRow style={{ marginBottom: 0 }}>
              <OccupationIcon />
              <Contact>{client.occupation}</Contact>
            </ContactSvgRow>
          )}
          {client?.key_words && client?.key_words.length > 0 && (
            <KeyWordsArea>
              {client.key_words.map((e, i) => (
                <KeyWord key={i}>{e}</KeyWord>
              ))}
            </KeyWordsArea>
          )}
        </InfoArea>
      </Content>
    </Modal>
  );
};

export default PersonalClientModal;

const KAMBAM_STEPS = [
  {
    id: 0,
    color: '#7f2c7c',
    title: 'Cadastro',
  },
  {
    id: 1,
    color: '#4a94f8',
    title: 'Retrospectiva de carreira',
  },
  {
    id: 2,
    color: '#56c991',
    title: 'Aprovar currículo',
  },
  {
    id: 3,
    color: '#ff6262',
    title: 'Formulário de autoanálise',
  },
  {
    id: 4,
    color: '#b55bcc',
    title: 'Discussão de autoanálise',
  },
  {
    id: 5,
    color: '#8075ff',
    title: 'Entrevista simulada',
  },
  {
    id: 6,
    color: '#bfb060',
    title: 'Dinâmica simulada',
  },
  {
    id: 7,
    color: '#ff72f1',
    title: 'Workshop linkedin',
  },
  {
    id: 8,
    color: '#56c991',
    title: 'Finalizado',
  },
];
const STATUS = [
  {
    id: 0,
    color: '#FF565685',
    textColor: '#7C0A0A',
    title: 'Indisponível',
  },
  {
    id: 1,
    color: '#ABDBCA',
    textColor: '#316F59',
    title: 'Disponível',
  },
];
