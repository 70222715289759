import { axiosManager } from '../../axios-manager';
import AdvisorCRUDProps from './index.d';

const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzX2luIjoiMjAyNC0xMC0wNyAxOTo0NzoxNC4yNzMzNTQiLCJjcmVhdGVkX2F0IjoiMjAyNC0wOS0wNyAxOTo0NzoxNC4yNzMzODciLCJ1c2VybmFtZSI6Im1hcmNvbmUiLCJ1c2VyX2lkIjoxfQ.3WAr8uTpAGbcvrMvy2xn_0OB3LftDxlzqG83A2L03bg';

class AdvisorCRUD {
  create({ mail, name, pass, photo }: AdvisorCRUDProps.createParams) {
    return axiosManager<AdvisorCRUDProps.createReturn>({
      method: 'post',
      url: `/advisor/create`,
      auth: token,
      payload: { email: mail, name, password: pass, image: photo },
    });
  }
  read({ page, per_page = 10 }: AdvisorCRUDProps.readParams) {
    return axiosManager<AdvisorCRUDProps.readReturn>({
      method: 'get',
      url: `/advisor/list?page=${page}&per_page=${per_page}`,
      auth: token,
      payload: null,
    });
  }
  update({ mail, name, pass, id, photo }: AdvisorCRUDProps.updateParams) {
    return axiosManager<AdvisorCRUDProps.updateReturn>({
      method: 'put',
      url: `/advisor/update/${id}`,
      auth: token,
      payload: { email: mail, name, password: pass, image: photo},
    });
  }
  delete({ id }: AdvisorCRUDProps.deleteParams) {
    return axiosManager<AdvisorCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/advisor/delete/${id}`,
      auth: token,
      payload: null,
    });
  }
}

export { AdvisorCRUD };
