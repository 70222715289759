import { axiosManager } from '../../axios-manager';
import VancancieCRUDProps from './index.d';

const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzX2luIjoiMjAyNC0xMC0wNyAxOTo0NzoxNC4yNzMzNTQiLCJjcmVhdGVkX2F0IjoiMjAyNC0wOS0wNyAxOTo0NzoxNC4yNzMzODciLCJ1c2VybmFtZSI6Im1hcmNvbmUiLCJ1c2VyX2lkIjoxfQ.3WAr8uTpAGbcvrMvy2xn_0OB3LftDxlzqG83A2L03bg';

class VancancieCRUD {
  create({
    name,
    benefits,
    budget,
    contact_name,
    contact_phone,
    contact_role,
    email,
    employment_type,
    key_words,
    level,
    occupation,
    perfil,
    quantity,
    sexual_gender,
    workplace,
    priority,
  }: VancancieCRUDProps.createParams) {
    return axiosManager<VancancieCRUDProps.createReturn>({
      method: 'post',
      url: `/vacancy/create`,
      auth: token,
      payload: {
        name,
        quantity,
        level,
        occupation,
        budget,
        sexual_gender,
        contact_name,
        contact_role,
        contact_phone,
        email,
        employment_type,
        benefits,
        perfil,
        workplace,
        key_words,
      },
    });
  }
  read({ page = 1, per_page = 10, id }: VancancieCRUDProps.readParams) {
    let url = `/vacancy/list?page=${page}&per_page=${per_page}`;
    if (id) url = url + `&id=${id}`;
    return axiosManager<VancancieCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: token,
      payload: null,
    });
  }
  update({
    name,
    benefits,
    budget,
    contact_name,
    contact_phone,
    contact_role,
    email,
    employment_type,
    key_words,
    level,
    occupation,
    perfil,
    quantity,
    sexual_gender,
    workplace,
    id,
  }: VancancieCRUDProps.updateParams) {
    return axiosManager<VancancieCRUDProps.updateReturn>({
      method: 'put',
      url: `/vacancy/update/${id}`,
      auth: token,
      payload: {
        name,
        quantity,
        // level,
        // occupation,
        budget,
        sexual_gender,
        contact_name,
        contact_role,
        contact_phone,
        email,
        employment_type,
        benefits,
        perfil,
        workplace,
        key_words,
      },
    });
  }
  delete({ id }: VancancieCRUDProps.deleteParams) {
    return axiosManager<VancancieCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/vacancy/delete/${id}`,
      auth: token,
      payload: null,
    });
  }
}

export { VancancieCRUD };
